import React, { useEffect, useState } from 'react';
import { userLoginType } from '@interfaces/UserInterface';
import userApi from '@api/userApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ExtraCrewDataStep1 from '@pages/SignedIn/Offer/ExtraCrewData/components/ExtraCrewDataStep1';
import ExtraCrewDataStep2 from '@pages/SignedIn/Offer/ExtraCrewData/components/ExtraCrewDataStep2';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthStackParamList } from '@src/routes';

export type extraCrewData = {
  ein: string;
  company_type: 'corp' | 'llc-single' | 'llc-multi' | 'partnership';
  direct_deposit: boolean;
};

type ExtraCrewDataProps = StackScreenProps<
  AuthStackParamList,
  'OfferExtraCrewData'
>;
export default function ExtraCrewData({
  navigation,
  route,
}: ExtraCrewDataProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [extraCrewData, setExtraCrewData] = useState<extraCrewData>(
    {} as extraCrewData
  );
  const [employmentType, setEmploymentType] = useState('');
  const [showBackButton, setShowBackButton] = useState<boolean>(true);

  useEffect(() => {
    checkIfIsOnboarding();

    setEmploymentType(route.params.employment);
  }, []);

  const checkIfIsOnboarding = async () => {
    let initialOnboarding = await AsyncStorage.getItem('initialOnboarding');
    if (initialOnboarding !== null) {
      setShowBackButton(false);
    }
  };

  useEffect(() => {
    getSignupDataFromLocalStorage();
  }, []);

  const getSignupDataFromLocalStorage = async () => {
    try {
      setStep(1);
    } catch (e) {
      console.log(e);
    }
  };

  const updateCrew = async (data: extraCrewData) => {
    setLoading(true);

    try {
      let response: userLoginType = await userApi.updateCrewDataForOffer(
        data,
        route.params.offerId
      );
      goToNextStep();
    } catch (e) {}

    setLoading(false);
  };

  const goToNextStep = async () => {
    navigation.navigate('OfferOnboarding', route.params);

    let initialOnboarding = await AsyncStorage.getItem('initialOnboarding');
    if (initialOnboarding !== null) {
      await AsyncStorage.setItem(
        'initialOnboarding',
        JSON.stringify({
          page: 'OfferOnboarding',
          params: route.params,
        })
      );
    }
  };

  return (
    <>
      {step == 1 && (
        <ExtraCrewDataStep1
          data={extraCrewData}
          updateData={(data: {}) => {
            if (employmentType == 'w2') {
              updateCrew({
                ...extraCrewData,
                ...data,
              });
              return;
            }

            setExtraCrewData({
              ...extraCrewData,
              ...data,
            });
            setStep(2);
          }}
          showBackButton={showBackButton}
        />
      )}

      {step == 2 && (
        <ExtraCrewDataStep2
          data={extraCrewData}
          updateData={(data: {}) => {
            updateCrew({
              ...extraCrewData,
              ...data,
            });
          }}
          employmentType={employmentType}
          loading={loading}
          showBackButton={showBackButton}
        />
      )}
    </>
  );
}
