import { TextInput as RNTextInput } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { transformTailwindToHex } from '@src/helpers';
import Flex from '@components/Views/Flex';
import Text from '@components/General/Text';

import FlexRow from '@components/Views/FlexRow';

type FormField = {
  label: string;
  value: string | null;
  required?: boolean;
  onChange: (value: string | null) => void;
};

export default function TimeInput({ required = true, ...props }: FormField) {
  let [hours, setHours] = useState<string | null>(null);
  let [minutes, setMinutes] = useState<string | null>(null);
  const ref_minute_input = useRef<RNTextInput | null>(null);

  useEffect(() => {
    if (props.value != null) {
      let valueArr = props.value.split(':');

      setHours(valueArr[0]);
      setMinutes(valueArr[1]);
    }
  }, [props.value]);

  useEffect(() => {
    let formattedHours = hours;

    if (formattedHours != null) {
      formattedHours += ':' + (minutes ?? '00');
    }

    props.onChange(formattedHours);
  }, [hours, minutes]);

  return (
    <Flex>
      <FlexRow align="space-between" verticalAlign="center">
        <RNTextInput
          placeholder="00"
          className={`w-11 p-3 h-11 rounded-xl text-sm border border-denim-20`}
          placeholderTextColor={transformTailwindToHex('denim-50')}
          autoCapitalize="none"
          value={hours}
          onChangeText={setHours}
          keyboardType="number"
          maxLength={2}
          returnKeyType="next"
          onSubmitEditing={() => ref_minute_input.current?.focus()}
          blurOnSubmit={false}
        />
        <Flex className="w-3" align="center" verticalAlign="center">
          <Text type="sm-medium" color="denim-50">
            :
          </Text>
        </Flex>
        <RNTextInput
          ref={ref_minute_input}
          placeholder="00"
          className={`w-11 p-3 h-11 rounded-xl text-sm border border-denim-20`}
          placeholderTextColor={transformTailwindToHex('denim-50')}
          autoCapitalize="none"
          value={minutes}
          onChangeText={setMinutes}
          keyboardType="number"
          maxLength={2}
        />
      </FlexRow>
    </Flex>
  );
}
