import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';

import FlexRow from '@components/Views/FlexRow';
import Flex from '@components/Views/Flex';
import Text from '@components/General/Text';

type TabsProps = {
  values: string[];
  defaultSelected: string | null;
  align?: string;
  onSelected: (value: string) => void;
  className?: string;
};
export const NewContentTabs = ({
  values,
  align = 'between',
  onSelected,
  defaultSelected,
  ...props
}: TabsProps) => {
  const [selected, setSelected] = React.useState(0);
  const updateSelected = (value: string, index: number) => {
    setSelected(index);
    onSelected(value.replace(' ', '-').toLowerCase());
  };

  useEffect(() => {
    if (defaultSelected != null) {
      const indexDefaultSelected = values.findIndex(
        (value) =>
          value.replace(' ', '-').toLowerCase() ===
          defaultSelected.replace(' ', '-').toLowerCase()
      );
      setSelected(indexDefaultSelected);
    }
  }, []);

  return (
    <FlexRow
      verticalAlign="center"
      align={align}
      className={`w-full bg-navy-light-100 h-10 justify-between shadow-lg text-white/40 border-b border-white/20`}
      {...props}
    >
      {values.map((value, index) => (
        <TouchableOpacity
          className={`h-10 px-2 w-1/2 border-b ${
            selected == index ? 'border-white' : 'border-transparent'
          }`}
          onPress={() => updateSelected(value, index)}
          key={index}
        >
          <Flex verticalAlign="center" align="center" className="flex-1">
            <Text
              type="sm-medium"
              color={selected == index ? 'white/100' : 'white/40'}
              className="text-center"
            >
              {value}
            </Text>
          </Flex>
        </TouchableOpacity>
      ))}
    </FlexRow>
  );
};
