import React, { useState, forwardRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import RBSheet from 'react-native-raw-bottom-sheet';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Button from '@components/Buttons/Button';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import CheckRounded from '@assets/icons/check-rounded.svg';
import { transformTailwindToHex } from '@src/helpers';

interface DeclineModalProps {
  onDecline: (type: number) => void;
  loading: boolean;
}

export const DeclineModal = forwardRef<RBSheet, DeclineModalProps>(({ onDecline, loading }, ref) => {
  const [declineType, setDeclineType] = useState(0);

  return (
    <RBSheet
      ref={ref}
      animationType="fade"
      height={600}
      customStyles={{
        container: {
          borderTopLeftRadius: 28,
          borderTopRightRadius: 28,
          width: getGlobal().deviceType === DeviceType.DESKTOP ? '500px' : '100%',
          marginHorizontal: getGlobal().deviceType === DeviceType.DESKTOP ? 'auto' : 0,
        },
      }}
    >
      <Flex className="py-8 h-full" verticalAlign="between">
        <Flex align="start">
          <Flex className="w-full px-8">
            <Text type="sm-semibold" className="mb-2" color="coral-100">
              Are you sure you want to decline?
            </Text>
            <Text type="xs-medium" className="mb-2" color="denim-50">
              You can opt to decline the current offer but let the company know you're willing to negotiate - this does not guarantee that you will be re-offered the role.
            </Text>
          </Flex>

          <Flex className="w-full gap-y-2 divide-y divide-denim-20 pt-8">
            {[
              { type: 0, label: 'Decline Offer', default: true },
              { type: 1, label: 'Decline but Willing to Negotiate' }
            ].map(({ type, label, default: isDefault }) => (
              <TouchableOpacity
                key={type}
                onPress={() => setDeclineType(type)}
                className={`${
                  declineType === type ? 'bg-coral-100/10' : 'bg-transparent'
                } py-2.5 border-b border-dashed ${
                  declineType === type ? 'border-denim-30' : 'border-transparent'
                }`}
              >
                <FlexRow className="py-2 px-8">
                  {declineType === type ? (
                    <CheckRounded
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('coral-100')}
                    />
                  ) : (
                    <View className="h-4 w-4 border border-denim-50 rounded-full" />
                  )}
                  <FlexRow align="between" className="ml-2">
                    <Text
                      type="sm-semibold"
                      color={declineType === type ? 'coral-100' : 'denim-50'}
                    >
                      {label}
                    </Text>
                    {isDefault && (
                      <Text
                        type="sm-semibold"
                        color={declineType === type ? 'coral-100' : 'denim-50'}
                      >
                        (default)
                      </Text>
                    )}
                  </FlexRow>
                </FlexRow>
              </TouchableOpacity>
            ))}
          </Flex>
        </Flex>
        <Flex className="gap-y-2 px-8">
          <Button
            value="Confirm Selection & Decline Offer"
            onPress={() => onDecline(declineType)}
            color="coral-100"
            useFullColor={true}
            loading={loading}
          />
          <SecondaryButton
            value="Back"
            className="mt-2"
            onPress={() => (ref as React.RefObject<RBSheet>).current?.close()}
          />
        </Flex>
      </Flex>
    </RBSheet>
  );
}); 