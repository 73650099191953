import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import { Fragment } from 'react';

export const GuarantedHours = ({hoursAmount}: {hoursAmount: string}) => {
  const hours = [
    {
      initials: 'Pr',
      title: 'Prep Days',
      value: '4 hours' || '-',
    },

    {
      initials: 'Sc',
      title: 'Scout Days',
      value: '-',
    },

    {
      initials: 'Tr',
      title: 'Travel Days',
      value: '10 hours',
    },

    {
      initials: 'Sh',
      title: 'Shoot Days',
      value: '6 hours',
    },

    {
      initials: 'Wr',
      title: 'Wrap Days',
      value: '10 hours',
    },
  ];

  const colors: {
    [key: string]: string;
  } = {
    Pr: '#F29A15',
    Sc: '#F9CA25',
    Tr: '#05DFAB',
    Sh: '#0062EC',
    Wr: '#7030A0',
  };

  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5 mt-10">
        Guaranted Hours (if applicable)
      </Text>

      <Flex className="bg-slate-800/5 py-4">
        <FlexRow align="between">
          <Text type="xs-medium" color="denim-50" className="px-6">
            Work Day Type
          </Text>
          <Text type="xs-medium" color="denim-50" className="px-6">
            Guaranteed Hours
          </Text>
        </FlexRow>
      </Flex>

      <Flex className="px-6">
        {hours.map((hour) => {
          return (
            <FlexRow
              key={hour.title}
              verticalAlign="center"
              align="between"
              className="h-11 border-b-0.5 border-denim-20"
            >
              <FlexRow verticalAlign="center">
                <Flex
                  className={`h-5 w-5 mr-2 rounded-md`}
                  align="center"
                  verticalAlign="center"
                  style={{
                    backgroundColor: colors[hour.initials],
                  }}
                >
                  <Text type="2xs-normal" color="white/100">
                    {hour.initials}
                  </Text>
                </Flex>
                <Text type="sm-medium" color="denim-50">
                  {hour.title}
                </Text>
              </FlexRow>
              <Text type="sm-medium" color="slate-800">
                {hoursAmount}
              </Text>
            </FlexRow>
          );
        })}
      </Flex>
    </Fragment>
  );
};
