import { ActivityIndicator, ScrollView, TouchableOpacity } from 'react-native';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import React, { useState } from 'react';
import Content from '@components/General/Content';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { navigationProps } from '@interfaces/RoutesInterface';
import { useNavigation } from '@react-navigation/native';
import FloatingBottom from '@components/Views/FloatingBottom';

type Step3Props = {
  productionId: string;
  nextStep: () => void;
};
export default function Step3({ productionId, nextStep }: Step3Props) {
  const navigation = useNavigation<navigationProps>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Flex className="flex-1 mt-5" verticalAlign="between">
        <ScrollView className="flex-1 px-5">
          <Flex className="flex-1">
            {loading && <ActivityIndicator className="mt-5" />}

            <Content title="Other Payments & Expenses" className="mt-8">
              <Flex className="-mt-3">
                <FlexRow
                  verticalAlign="center"
                  align="between"
                  className="h-11 border-b-0.5 border-denim-20"
                >
                  <FlexRow
                    verticalAlign="center"
                    align="start"
                    className="flex-grow"
                  >
                    <Text type="xs-semibold" color="navy-100">
                      May 23
                    </Text>
                    <Text type="xs-medium" color="denim-50" className="ml-2">
                      Bonus
                    </Text>
                  </FlexRow>
                  <Text type="xs-semibold" color="denim-100">
                    $150.00
                  </Text>
                </FlexRow>

                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('ProductionPayment', {
                      productionId: productionId,
                    })
                  }
                >
                  <FlexRow
                    className="h-11 border-b-0.5 border-denim-20"
                    verticalAlign="center"
                    align="start"
                  >
                    <Text type="sm-medium" color="denim-100">
                      Add Payments
                    </Text>
                  </FlexRow>
                </TouchableOpacity>

                <Text
                  type="xs-medium"
                  color="denim-50"
                  className="text-right mt-2"
                >
                  Total Payments: $0.00
                </Text>
              </Flex>
            </Content>
          </Flex>
        </ScrollView>
        <FloatingBottom>
          <Button
            useFullColor={true}
            value="Next"
            color="navy-light-100"
            className="mt-2"
            onPress={() => nextStep()}
          />
        </FloatingBottom>
      </Flex>
    </>
  );
}
