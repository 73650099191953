import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import productionApi from '@src/api/productionApi';
import SecondaryButton from '@src/components/Buttons/SecondaryButton';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import Header from '@src/components/Views/Header';
import {
  formatDateDefault,
  formatDateDefaultYear,
  translatedAmount,
} from '@src/helpers';
import { productionType } from '@src/interfaces/ProductionInterface';
import { navigationProps } from '@src/interfaces/RoutesInterface';
import { AuthStackParamList } from '@src/routes';
import { useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  SafeAreaView,
  Linking,
  TouchableOpacity,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type PaystubProps = StackScreenProps<AuthStackParamList, 'ProductionPaystub'>;

type Withholding = {
  label: string;
  amount: number;
};

type Wage = {
  label: string;
  amount: number;
  hours: number;
};

type Period = {
  production_state: string | null;
  gross_pay: number;
  gross_hours: number;
  hourly_rate: number;
  net_pay: number;
  wages: Wage[];
  other_taxable: any[]; // Can be refined if specific structure is known
  withholdings: Withholding[];
};

type YearToDate = Omit<Period, 'hourly_rate' | 'production_state'>;

type RoleRate = {
  type: 'hour-day';
  hours: number;
  amount: number;
  guaranteed_hours: number;
};

type RoleAttributes = {
  name: string;
  rate: RoleRate;
  employment: string;
};

type ProductionAttributes = {
  name: string;
  company: string;
};

type RelatedData<T> = {
  data: {
    id: string;
    attributes: T;
    related: any[]; // Can be refined if related structure is known
  };
};

type Related = {
  production: RelatedData<ProductionAttributes>;
  role: RelatedData<RoleAttributes>;
};

type Attributes = {
  start_date: string;
  end_date: string;
  file: any | null;
  period: Period;
  'year-to-date': YearToDate;
};

type Paystub = {
  id: string;
  attributes: Attributes;
  related: Related;
};

export const Paystub = ({ route }: PaystubProps) => {
  const [id, setId] = useState(route.params.id);
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [paystubList, setPaystubList] = useState<Paystub[]>([]);
  const [paystub, setPaystub] = useState<Paystub | null>(null);
  const navigation = useNavigation<navigationProps>();

  const [production, setProduction] = useState<productionType | null>(null);

  useFocusEffect(
    useCallback(() => {
      getProduction();
      getPaystubList();
    }, [])
  );

  const getPaystubList = async () => {
    setLoading(true);

    let response = await productionApi.getPaystubList(
      `?filters[production]=${route.params.productionId}`
    );
    setPaystubList(response.data);

    setLoading(false);
  };

  const getProduction = async () => {
    setLoading(true);
    let response: { data: productionType } = await productionApi.getProduction(
      route.params.productionId
    );

    setProduction(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getPaystub(route.params.id);
  }, [id]);

  const getPaystub = async (paystubId: string) => {
    setLoading(true);

    let response = await productionApi.getPaystub(paystubId);
    setPaystub(response.data);

    setLoading(false);
  };

  if (loading || production == null) {
    return (
      <SafeAreaView className="flex-1 bg-white p-5">
        <ActivityIndicator />
      </SafeAreaView>
    );
  }

  const renderPaystub = () => {
    return (
      <Flex>
        {/* <FlexRow
                    className="flex-grow gap-y-1 pb-5 border-b-0.5 border-denim-20 mb-4 -mx-5 px-5 pt-5"
                    verticalAlign="center"
                    align="between"
                >
                    <Text type="sm-semibold" color="navy-100">
                        {formatDateDefault(paystub?.attributes?.start_date?)} -{' '}
                        {formatDateDefaultYear(paystub?.attributes?.end_date?)}
                    </Text>
                    {paystub?.attributes?.file? != null && (
                        <Text type="xs-medium" color="emerald-100">
                            Paid
                        </Text>
                    )}
                </FlexRow> */}

        <Flex className="gap-y-1">
          <FlexRow verticalAlign="center" align="between">
            <Text type="xs-medium" color="denim-50">
              Net Pay (This Period)
            </Text>
            <Text type="xs-medium" color="denim-50">
              Hourly Rate
            </Text>
          </FlexRow>

          <FlexRow verticalAlign="center" align="between">
            <Text type="sm-medium" color="emerald-100">
              $
              {(
                ((paystub?.attributes?.period?.net_pay || 0) * 100) /
                100
              ).toFixed(2)}
            </Text>
            <Text type="sm-medium" color="denim-100">
              {translatedAmount(paystub?.attributes?.period?.hourly_rate || 0)}
            </Text>
          </FlexRow>
        </Flex>

        <Flex>
          {(paystub?.attributes?.period?.wages || []).length > 0 && (
            <Flex>
              <FlexRow
                className="mt-5 border-b-0.5 border-denim-20 h-7"
                verticalAlign="center"
                align="between"
              >
                <Text type="xs-medium" color="navy-100" className="flex-1">
                  Wages
                </Text>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="denim-50">
                    Hours
                  </Text>
                </FlexRow>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="denim-50">
                    Earnings
                  </Text>
                </FlexRow>
              </FlexRow>

              {paystub?.attributes?.period?.wages.map((value, index) => {
                return (
                  <FlexRow
                    className="h-6"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="xs-medium" color="denim-50" className="flex-1">
                      {value.label}
                    </Text>
                    <FlexRow className="flex-1" align="end">
                      <Text type="xs-medium" color="denim-100">
                        {value.hours}
                      </Text>
                    </FlexRow>
                    <FlexRow className="flex-1" align="end">
                      <Text type="xs-medium" color="denim-100">
                        ${((value.amount * 100) / 100).toFixed(2)}
                      </Text>
                    </FlexRow>
                  </FlexRow>
                );
              })}

              <FlexRow className="h-6" verticalAlign="center" align="between">
                <Text type="xs-medium" color="navy-100" className="flex-1">
                  Total
                </Text>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="navy-100">
                    {paystub?.attributes?.period?.wages.reduce(
                      (n, { hours }) => n + hours,
                      0
                    )}
                  </Text>
                </FlexRow>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="navy-100">
                    $
                    {(
                      ((paystub?.attributes?.period?.wages || []).reduce(
                        (n, { amount }) => n + amount,
                        0
                      ) *
                        100) /
                      100
                    ).toFixed(2)}
                  </Text>
                </FlexRow>
              </FlexRow>
            </Flex>
          )}

          {(paystub?.attributes?.period?.other_taxable || []).length > 0 && (
            <Flex>
              <FlexRow
                className="mt-5 border-b-0.5 border-denim-20 h-7"
                verticalAlign="center"
                align="between"
              >
                <Text type="xs-medium" color="navy-light-100">
                  Other Taxable
                </Text>
                <Text type="xs-medium" color="denim-50">
                  Earnings
                </Text>
              </FlexRow>

              {paystub?.attributes?.period?.other_taxable.map(
                (value, index) => {
                  return (
                    <FlexRow
                      className="h-6"
                      verticalAlign="center"
                      align="between"
                    >
                      <Text type="xs-medium" color="denim-50">
                        {value.label}
                      </Text>
                      <Text type="xs-medium" color="denim-100">
                        ${((value.amount * 100) / 100).toFixed(2)}
                      </Text>
                    </FlexRow>
                  );
                }
              )}

              <FlexRow className="h-6" verticalAlign="center" align="between">
                <Text type="xs-medium" color="navy-light-100">
                  Total
                </Text>
                <Text type="xs-medium" color="navy-light-100">
                  $
                  {(
                    (paystub?.attributes?.period?.other_taxable.reduce(
                      (n, { amount }) => n + amount,
                      0
                    ) *
                      100) /
                    100
                  ).toFixed(2)}
                </Text>
              </FlexRow>
            </Flex>
          )}

          {(paystub?.attributes?.period?.withholdings || []).length > 0 &&
            production.attributes.employment != 'contractor' && (
              <Flex>
                <FlexRow
                  className="mt-5 border-b-0.5 border-denim-20 h-7"
                  verticalAlign="center"
                  align="between"
                >
                  <Text type="xs-medium" color="coral-100">
                    Withholdings
                  </Text>
                  <Text type="xs-medium" color="denim-50">
                    Earnings
                  </Text>
                </FlexRow>

                {paystub?.attributes?.period?.withholdings.map(
                  (value, index) => {
                    return (
                      <FlexRow
                        className="h-6"
                        verticalAlign="center"
                        align="between"
                      >
                        <Text type="xs-medium" color="denim-50">
                          {value.label}
                        </Text>
                        <Text type="xs-medium" color="denim-100">
                          ${((value.amount * 100) / 100).toFixed(2)}
                        </Text>
                      </FlexRow>
                    );
                  }
                )}

                <FlexRow className="h-6" verticalAlign="center" align="between">
                  <Text type="xs-medium" color="coral-100">
                    Total
                  </Text>
                  <Text type="xs-medium" color="coral-100">
                    $
                    {(
                      ((paystub?.attributes?.period?.withholdings || []).reduce(
                        (n, { amount }) => n + amount,
                        0
                      ) *
                        100) /
                      100
                    ).toFixed(2)}
                  </Text>
                </FlexRow>
              </Flex>
            )}
        </Flex>
        {paystub?.attributes?.file && (
          <SecondaryButton
            value={'Download Paystub'}
            className="mt-5"
            onPress={() => Linking.openURL(paystub?.attributes?.file)}
          />
        )}
        <SecondaryButton
          value={'Bank Details'}
          className="mt-2"
          onPress={() => null}
          disabled={true}
        />
      </Flex>
    );
  };

  if (loading) {
    return (
      <Flex className="flex-1">
        <ActivityIndicator className="mt-5" />
      </Flex>
    );
  }

  if (!loading && paystub == null) {
    return (
      <Flex className="flex-1 pt-5" align="center">
        <Text type="sm-semibold" color="denim-50">
          No data to display
        </Text>
      </Flex>
    );
  }

  const isPreviousDisabled =
    paystubList.length > 0 && paystubList[0].id === route.params.id;
  const isNextDisabled =
    paystubList.length > 0 &&
    paystubList[paystubList.length - 1].id === route.params.id;

  return (
    <Flex>
      <Header
        title={'Paystubs'}
        onPressBack={() =>
          navigation.navigate('Production', {
            productionId: production.id,
            tab: 'your-role',
            subtab: 'paystubs',
          })
        }
      />
      <FlexRow className="justify-between items-center border-b border-gray-300 py-2.5 px-6">
        <TouchableOpacity
          disabled={isPreviousDisabled}
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isPreviousDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          onPress={() => {
            const index = paystubList.findIndex(
              (ps) => ps.id === route.params.id
            );

            setId(paystubList[index - 1].id);
          }}
        >
          <ArrowLeft width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
        <Flex className="justify-center items-center">
          <Text type="base-semibold" color="black" className="text-center">
            {formatDateDefault(paystub?.attributes?.start_date)} -{' '}
            {formatDateDefaultYear(paystub?.attributes?.end_date)}
          </Text>

          <Text type="xs-semibold" color="emerald-80">
            Wrapped
          </Text>
        </Flex>
        <TouchableOpacity
          disabled={isNextDisabled}
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isNextDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          onPress={() => {
            const index = paystubList.findIndex(
              (ps) => ps.id === route.params.id
            );

            setId(paystubList[index + 1].id);
          }}
        >
          <ArrowRight width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
      </FlexRow>
      <ScrollView
        className="flex-1 px-6 pt-5"
        style={{
          paddingBottom: insets.bottom == 0 ? 20 : insets.bottom,
        }}
      >
        {renderPaystub()}
      </ScrollView>
    </Flex>
  );
};
