import SecondaryButton from '@src/components/Buttons/SecondaryButton';
import { ActivityIndicator } from 'react-native';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import moment from 'moment';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import FloatingBottom from '@src/components/Views/FloatingBottom';
import Button from '@src/components/Buttons/Button';
import { Time, Timecard } from '../../WorkSchedule';
import React, { useEffect, useState } from 'react';
import { ShootType } from '@src/interfaces/ProductionInterface';
import productionApi from '@src/api/productionApi';
import { DailyRow } from '../DailyRow/DailyRow';
import { DailyMealRow } from '../DailyMealRow/DailyMealRow';
import { isEmpty } from '@src/helpers';
import { useFocusEffect } from '@react-navigation/native';
import { getElapsedHours } from '@src/helpers/get-elapsed-hours';

type DailyViewProps = {
  onBackToWeekly: () => void;
  timecardId: string;
  day: Time;
  onChangeDay: (day: Time) => void;
};

export const DailyView = ({
  onBackToWeekly,
  day,
  timecardId,
  onChangeDay,
}: DailyViewProps) => {
  const [timecard, setTimecard] = useState<Timecard | null>(null);
  const [shootDay, setShootDay] = useState<ShootType | null>(null);
  const [loading, setLoading] = useState(false);
  const insets = useSafeAreaInsets();
  const [showDonePopup, setShowDonePopup] = useState<boolean>(true);
  useFocusEffect(
    React.useCallback(() => {
      setShowDonePopup(true);
    }, [timecardId, day])
  );

  useEffect(() => {
    getTimecard(timecardId);
  }, [timecardId]);

  const getTimecard = async (timecardId: string) => {
    setLoading(true);

    let response = await productionApi.getTimecard(timecardId);
    setTimecard(response.data);

    setLoading(false);
  };

  useEffect(() => {
    if (timecard) getShootDay();
  }, [day, timecard]);

  const getShootDay = async () => {
    if (timecard == null) return;

    setLoading(true);

    const shootDayResponse: { data: ShootType } = await productionApi.getShoot(
      day?.id
    );

    setShootDay(shootDayResponse?.data);
    setLoading(false);
  };

  const colors: {
    [key: string]: string;
  } = {
    Pr: '#F29A15',
    Sc: '#F9CA25',
    Tr: '#05DFAB',
    Sh: '#0062EC',
    Wr: '#7030A0',
  };

  const isMealCompleted = (meal: any, mealNumber: number) => {
    if (shootDay == null) return false;

    if (shootDay.attributes.required_meals_count == 0 || meal?.penalty) {
      return true;
    }

    if (shootDay.attributes.required_meals_count == 1 && mealNumber == 1) {
      return !isEmpty(meal?.start_time);
    }

    if (shootDay.attributes.required_meals_count == 2 && mealNumber == 2) {
      return !isEmpty(meal?.start_time);
    }

    return true;
  };

  const isComplete =
    !isEmpty(shootDay?.attributes.checkin_at) &&
    !isEmpty(shootDay?.attributes.checkout_at) &&
    isMealCompleted(shootDay?.attributes.meal1, 1) &&
    isMealCompleted(shootDay?.attributes.meal2, 2);

  const isPreviousDisabled =
    timecard &&
    timecard?.attributes?.times?.length > 0 &&
    timecard?.attributes.times[0].id === shootDay?.id;
  const isNextDisabled =
    timecard &&
    timecard?.attributes.times.length > 0 &&
    timecard?.attributes.times[timecard?.attributes.times.length - 1].id ===
      shootDay?.id;

  if (loading || timecard == null || shootDay == null) {
    return <ActivityIndicator className="mt-5" />;
  }

  const getStatus = () => {
    if (timecard.attributes.status === 'approved') {
      return (
        <Text
          type="xs-medium"
          className="w-full text-center"
          color="emerald-100"
        >
          Approved
        </Text>
      );
    }

    if (
      shootDay.attributes.is_locked &&
      shootDay.attributes.checkout_by != null &&
      shootDay.attributes.checkout_by == 'Self'
    ) {
      return (
        <Text
          type="xs-medium"
          className="w-full text-center"
          color="navy-light-100"
        >
          Admin Confirmed
        </Text>
      );
    }

    if (
      shootDay.attributes.is_locked &&
      shootDay.attributes.checkout_by != null &&
      shootDay.attributes.checkout_by !== 'Self'
    ) {
      return (
        <Text type="xs-medium" className="w-full text-center" color="grape-100">
          Admin Updated
        </Text>
      );
    }

    if (moment().isBefore(moment(shootDay.attributes.date))) {
      return (
        <Text type="xs-medium" className="w-full text-center" color="denim-50">
          Scheduled
        </Text>
      );
    }

    if (isComplete) {
      return (
        <Text
          type="xs-medium"
          className="w-full text-center"
          color="navy-light-100"
        >
          Complete
        </Text>
      );
    }

    return (
      <Text type="xs-medium" className="w-full text-center" color="coral-100">
        Incomplete
      </Text>
    );
  };

  return (
    <ScrollView>
      <FlexRow className="justify-between items-center py-2.5 px-6">
        <TouchableOpacity
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isPreviousDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          disabled={isPreviousDisabled}
          onPress={() => {
            const index = timecard.attributes.times.findIndex(
              (time) => time.id === shootDay.id
            );

            onChangeDay(timecard.attributes.times[index - 1]);
          }}
        >
          <ArrowLeft width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
        <Flex className="justify-center items-center">
          <Text type="2xs-semibold" color="denim-50">
            {moment(shootDay.attributes.date).format('dddd')}
          </Text>
          <Text type="base-semibold" color="black" className="text-center">
            {moment(shootDay.attributes.date).format('MMM DD, YYYY')}
          </Text>
          {getStatus()}
        </Flex>
        <TouchableOpacity
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isNextDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          disabled={isNextDisabled}
          onPress={() => {
            const index = timecard.attributes.times.findIndex(
              (time) => time.id === shootDay.id
            );

            onChangeDay(timecard.attributes.times[index + 1]);
          }}
        >
          <ArrowRight width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
      </FlexRow>
      <FlexRow align="between" verticalAlign="center" className="px-6 py-5">
        <FlexRow verticalAlign="center">
          {/* <Flex
            className={`h-5 w-5 mr-2 rounded-md`}
            align="center"
            verticalAlign="center"
            style={{
              backgroundColor: colors['Pr'],
            }}
          >
            <Text type="2xs-normal" color="white/100">
              {'Pr'}
            </Text>
          </Flex>
          <Text type="sm-medium" color="denim-50">
            Prep Day
          </Text> */}
        </FlexRow>
        <Text type="sm-medium" color="denim-50">
          {(shootDay.attributes.location as { address: string }).address}
        </Text>
      </FlexRow>

      {timecard.attributes.is_approved && (
        <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            This week has been wrapped - all daily timecards & other payments
            are locked.
          </Text>
        </Flex>
      )}

      {!timecard.attributes.is_approved &&
        moment().isBefore(moment(shootDay.attributes.date)) && (
          <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
            <Text type="xs-medium" color="denim-50" className="px-6">
              This Work Day is not active yet - all timecard fields will remain
              locked until the scheduled date.
            </Text>
          </Flex>
        )}

      {!timecard.attributes.is_approved &&
        shootDay.attributes.is_locked &&
        shootDay.attributes.checkout_by != null && (
          <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
            <Text type="xs-medium" color="denim-50" className="px-6">
              This timecard has been confirmed and locked by an Admin - no
              further edits can be made.
            </Text>
          </Flex>
        )}

      {!moment().isBefore(moment(shootDay.attributes.date)) &&
        !shootDay.attributes.is_locked && (
          <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
            <Text type="xs-medium" color="denim-50" className="px-6">
              Complete all required fields below - you can edit any entries
              until they are locked by an Admin.
            </Text>
          </Flex>
        )}
      <Flex className="w-full pb-20">
        <DailyRow
          disabled={false}
          value={shootDay.attributes.checkin_at}
          label={'Time In'}
          locked={shootDay.attributes.is_locked}
          shootId={shootDay.id}
          completedBy={shootDay.attributes.checkin_by}
          type="checkin_time"
          date={shootDay.attributes.date}
          approved={timecard.attributes.status === 'approved'}
          updateTimeCard={(data) => setShootDay(data)}
        />
        <DailyRow
          disabled={false}
          value={shootDay.attributes.checkout_at}
          label={'Time Out'}
          locked={shootDay.attributes.is_locked}
          shootId={shootDay.id}
          completedBy={shootDay.attributes.checkout_by}
          type="checkout_time"
          date={shootDay.attributes.date}
          approved={timecard.attributes.status === 'approved'}
          updateTimeCard={(data) => setShootDay(data)}
        />
        <DailyMealRow
          disabled={false}
          required={shootDay.attributes.required_meals_count > 0}
          value={shootDay.attributes.meal1}
          label={'Meal 1'}
          locked={shootDay.attributes.is_locked}
          shootId={shootDay.id}
          completedBy={
            shootDay.attributes.meal1 ? shootDay.attributes.meals_by : null
          }
          type="meal1"
          approved={timecard.attributes.status === 'approved'}
          date={shootDay.attributes.date}
          updateTimeCard={(data) => setShootDay(data)}
        />

        <DailyMealRow
          disabled={false}
          required={shootDay.attributes.required_meals_count == 2}
          value={shootDay.attributes.meal2}
          label={'Meal 2'}
          locked={shootDay.attributes.is_locked}
          shootId={shootDay.id}
          completedBy={
            shootDay.attributes.meal2 ? shootDay.attributes.meals_by : null
          }
          type="meal2"
          approved={timecard.attributes.status === 'approved'}
          date={shootDay.attributes.date}
          updateTimeCard={(data) => setShootDay(data)}
        />
      </Flex>
      <Flex className="w-full">
        <Flex
          className={`px-5 pt-5 w-full bg-white border-t border-denim-20 mt-5 fixed bottom-0 z-10`}
          style={{
            shadowColor: '#131E3C',
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowOpacity: 0.2,
            shadowRadius: 34,
            elevation: 10,
          }}
        >
          <FlexRow align="between" verticalAlign="center">
            <TouchableOpacity onPress={onBackToWeekly}>
              <FlexRow verticalAlign="center">
                <ArrowLeft width={12} height={12} fill="#0062EC" />
                <Text className="ml-2" color="navy-light-100">
                  Back to Week
                </Text>
              </FlexRow>
            </TouchableOpacity>
            <Text type="sm-medium" color="denim-50">
              Elapsed:{' '}
              {getElapsedHours(
                shootDay.attributes.checkin_at,
                shootDay.attributes.checkout_at
              )}{' '}
              hrs
            </Text>
          </FlexRow>
        </Flex>
      </Flex>
      {showDonePopup &&
        !shootDay.attributes.is_locked &&
        !isEmpty(shootDay.attributes.checkin_at) &&
        !isEmpty(shootDay.attributes.checkout_at) &&
        isMealCompleted(shootDay.attributes.meal1, 1) &&
        isMealCompleted(shootDay.attributes.meal2, 2) && (
          <FloatingBottom fixBottomPadding>
            <Text type="sm-medium" color="navy-100">
              Timecard Complete!
            </Text>
            <SecondaryButton
              value="Keep Editing"
              className="mt-5 mb-2"
              onPress={() => setShowDonePopup(false)}
            />
            <Button
              useFullColor={true}
              value="Go To Weekly View"
              onPress={onBackToWeekly}
              color="navy-light-100"
            />
          </FloatingBottom>
        )}
    </ScrollView>
  );
};
