import { Linking } from 'react-native';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import { formatDateDefaultYear } from '@src/helpers';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { productionType } from '@src/interfaces/ProductionInterface';

type DocumentsProps = {
  production: productionType;
};
export const Documents = ({ production }: DocumentsProps) => {
  return (
    <>
      <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
        <Text type="xs-medium" color="denim-50" className="px-6">
          All submitted tax and identification documents can be saved once
          countersigned by an Admin.
        </Text>
      </Flex>
      {production.related.documents?.data.map((item) => (
        <FlexRow
          className="py-4 border-b border-denim-10"
          verticalAlign="center"
        >
          <Flex className="flex-grow pl-6">
            <FlexRow align="between" verticalAlign="center">
              <Text type="sm-medium">{item.attributes.title}</Text>
            </FlexRow>
            <Text type="xs-medium" color="denim-50">
              Last Updated:{' '}
              {formatDateDefaultYear(item.attributes.submitted_at)}
            </Text>
          </Flex>

          {item.attributes.status == 'pending' && (
            <Flex verticalAlign="start" align="end" className="pr-6">
              <Text type="xs-medium" color="coral-100">
                Pending
              </Text>
            </Flex>
          )}

          {item.attributes.status == 'submitted' && (
            <Flex verticalAlign="start" align="end" className="pr-6">
              <Text type="xs-medium" color="orange-100">
                Pending Countersign
              </Text>
            </Flex>
          )}

          {item.attributes.file !== null &&
            item.attributes.status == 'completed' && (
              <Flex verticalAlign="start" align="end" className="pr-6">
                <TouchableOpacity
                  onPress={() => Linking.openURL(item.attributes.file)}
                >
                  <Text type="xs-medium" color="navy-light-100">
                    View
                  </Text>
                </TouchableOpacity>
              </Flex>
            )}
        </FlexRow>
      ))}
    </>
  );
};
