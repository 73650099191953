import { View } from 'react-native';
import React from 'react';
import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';

type ContentProps = {
  title: string;
  color?: string;
  className?: string;
  children: React.ReactNode;
};

export default function Content({
  color = 'navy-100',
  ...props
}: ContentProps) {
  return (
    <Flex className="mb-8" {...props}>
      <Flex
        className="mb-3 pb-2 border-denim-20 border-b-0.5"
        verticalAlign="end"
        align="between"
      >
        <Text type="xs-medium" color={color}>
          {props.title}
        </Text>
      </Flex>
      {props.children}
    </Flex>
  );
}
