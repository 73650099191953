import React, { useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import productionApi from '@api/productionApi';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Tag from '@components/General/Tag';
import Text from '@components/General/Text';
import { formatDateDefault } from '@src/helpers';
import { RefreshControl } from 'react-native-web-refresh-control';
import NoData from '@components/General/NoData';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { navigationProps } from '@interfaces/RoutesInterface';

export default function TimecardsHistory() {
  const navigation = useNavigation<navigationProps>();

  const [timecards, setTimecards] = useState<any>([]); // @TODO: Fix this type
  const [loading, setLoading] = useState<boolean>(true);
  const insets = useSafeAreaInsets();

  useFocusEffect(
    React.useCallback(() => {
      getTimecards();
    }, [])
  );

  const getTimecards = async () => {
    setLoading(true);

    let response = await productionApi.getTimecardList();
    setTimecards(response.data);

    setLoading(false);
  };

  return (
    <Flex className="flex-1 px-5">
      <ScrollView
        className="flex-1"
        style={{
          paddingBottom: insets.bottom == 0 ? 20 : insets.bottom,
        }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={getTimecards} />
        }
      >
        {timecards.length == 0 && !loading && (
          <NoData text="You don't have any timecards yet." />
        )}

        {timecards.length > 0 &&
          !loading &&
          timecards.map((timecard, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  navigation.navigate('Production', {
                    productionId: timecard.related.production.data.id,
                    tab: 'work-schedule',
                    timecardId: timecard.id,
                    subtab: 'timecards',
                  });
                }}
              >
                <Flex className="border-b border-gray-200 py-4">
                  <FlexRow
                    verticalAlign="center"
                    align="between"
                    className="mb-2"
                  >
                    <Text type="sm-medium">
                      {timecard.related.production.data.attributes.name}
                    </Text>
                    <Tag type={timecard.attributes.status} />
                  </FlexRow>
                  <FlexRow
                    verticalAlign="center"
                    align="between"
                    className="mb-1"
                  >
                    <Text type="xs-medium" color="denim-50">
                      Week {timecard.id.replace('-', ' of ')}
                    </Text>
                    <Text type="xs-medium" color="denim-50">
                      {formatDateDefault(timecard.attributes.start_date)} -{' '}
                      {formatDateDefault(timecard.attributes.end_date)}
                    </Text>
                  </FlexRow>
                </Flex>
              </TouchableOpacity>
            );
          })}
      </ScrollView>
    </Flex>
  );
}
