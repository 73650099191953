import Text from '@components/General/Text';
import MainLogo from '@components/General/MainLogo';
import { ScrollView, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import { setGlobal } from 'reactn';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { userLoginType } from '@interfaces/UserInterface';
import userApi from '@api/userApi';
import { StackScreenProps } from '@react-navigation/stack';
import { NonAuthStackParamList } from '../../../routes';
import TextInput from '@components/Forms/TextInput';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AlertCircle from '@assets/icons/alert-circle.svg';

import * as Linking from 'expo-linking';
import FlexRow from '@components/Views/FlexRow';
import { transformTailwindToHex } from '@src/helpers';

type LoginProps = StackScreenProps<NonAuthStackParamList, 'Login'>;
export default function Login({ navigation }: LoginProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const insets = useSafeAreaInsets();
  const [offerAlert, setOfferAlert] = useState<boolean>(false);

  useEffect(() => {
    checkUrlLink();
  }, []);

  const login = async () => {
    setLoading(true);

    try {
      let response: userLoginType = await userApi.login({
        email: email,
        password: password,
      });

      await AsyncStorage.setItem('access_token', response.meta.access_token);
      await AsyncStorage.setItem(
        'user',
        JSON.stringify(response.data.attributes)
      );
      await setGlobal({
        user: response.data.attributes,
        access_token: response.meta.access_token,
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const checkUrlLink = async () => {
    const initialUrl = await Linking.getInitialURL();

    if (!initialUrl) {
      return;
    }

    const { path } = Linking.parse(initialUrl);

    if (path?.includes('offer-summary')) {
      let offerId = path.split('/')[1];

      await AsyncStorage.setItem(
        'redirectTo',
        JSON.stringify({
          page: 'Offer',
          params: {
            offerId: offerId,
          },
        })
      );

      setOfferAlert(true);
    }
  };

  return (
    <Flex className="flex-1">
      <View className="h-1" />
      <Flex
        className="bg-navy-light-100"
        verticalAlign="content-between"
        style={{ paddingTop: insets.top }}
      >
        <Flex className="px-5 gap-y-2 pb-3">
          <Flex className="h-28">
            <MainLogo />
          </Flex>

          <Flex>
            <Text type="sm-semibold" className="mb-3" color="white/100">
              Welcome to FilmUp
            </Text>
            <Text type="xs-normal" className="mb-3" color="white/100">
              Follow up on the login to access the content inside the app.
              Please contact Filmup Support if you forgot your password
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="flex-1 bg-white">
        <ScrollView className="flex-1 px-5 py-5">
          <Flex>
            {offerAlert && (
              <FlexRow
                verticalAlign="center"
                align="start"
                className="p-2 bg-coral-10 rounded-xl border border-coral-100 mb-5"
              >
                <AlertCircle
                  width={16}
                  height={16}
                  fill={transformTailwindToHex('coral-100')}
                />
                <Text type="xs-medium" color="coral-100" className="ml-2">
                  You got an offer! Log in to access it.
                </Text>
              </FlexRow>
            )}

            <TextInput label="Email" onChange={setEmail} value={email} />
            <TextInput
              label="Password"
              password={true}
              onChange={setPassword}
              value={password}
            />
          </Flex>
        </ScrollView>

        <Flex
          className="px-5 gap-y-2"
          style={{
            paddingBottom: insets.bottom == 0 ? 20 : insets.bottom,
          }}
        >
          <Button
            value="Login"
            useFullColor={true}
            onPress={() => login()}
            className="mt-10"
            loading={loading}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
