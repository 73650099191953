import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';

import Flex from '@components/Views/Flex';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SubContentTabs from '@components/General/SubContentTabs';

import Text from '@components/General/Text';
import productionApi from '@api/productionApi';
import FlexRow from '@components/Views/FlexRow';

export default function PaystubHistoryByYear() {
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<string>('2024');
  const [paystub, setPaystub] = useState<any>(null);

  useEffect(() => {
    getPaystub(selectedTab);
  }, [selectedTab]);

  const getPaystub = async (year: string) => {
    setLoading(true);

    let response = await productionApi.getYearlyPaystub(year);
    setPaystub(response.data);

    setLoading(false);
  };

  const renderPaystub = () => {
    if (paystub == null) {
      return null;
    }

    return (
      <Flex>
        <Flex className="gap-y-1 pt-5">
          <Text type="xs-medium" color="denim-50">
            Net Pay (YTD)
          </Text>
          <Text type="sm-medium" color="emerald-100">
            ${((paystub.net_pay * 100) / 100).toFixed(2)}
          </Text>
        </Flex>

        <Flex>
          {paystub.wages.length > 0 && (
            <Flex>
              <FlexRow
                className="mt-5 border-b-0.5 border-denim-20 h-7"
                verticalAlign="center"
                align="between"
              >
                <Text type="xs-medium" color="navy-100" className="flex-1">
                  Wages
                </Text>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="denim-50">
                    Hours
                  </Text>
                </FlexRow>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="denim-50">
                    Earnings
                  </Text>
                </FlexRow>
              </FlexRow>

              {paystub.wages.map((value, index) => {
                return (
                  <FlexRow
                    className="h-6"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="xs-medium" color="denim-50" className="flex-1">
                      {value.label}
                    </Text>
                    <FlexRow className="flex-1" align="end">
                      <Text type="xs-medium" color="denim-100">
                        {value.hours}
                      </Text>
                    </FlexRow>
                    <FlexRow className="flex-1" align="end">
                      <Text type="xs-medium" color="denim-100">
                        ${((value.amount * 100) / 100).toFixed(2)}
                      </Text>
                    </FlexRow>
                  </FlexRow>
                );
              })}

              <FlexRow className="h-6" verticalAlign="center" align="between">
                <Text type="xs-medium" color="navy-100" className="flex-1">
                  Total
                </Text>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="navy-100">
                    {paystub.wages.reduce((n, { hours }) => n + hours, 0)}
                  </Text>
                </FlexRow>
                <FlexRow className="flex-1" align="end">
                  <Text type="xs-medium" color="navy-100">
                    $
                    {(
                      (paystub.wages.reduce((n, { amount }) => n + amount, 0) *
                        100) /
                      100
                    ).toFixed(2)}
                  </Text>
                </FlexRow>
              </FlexRow>
            </Flex>
          )}

          {paystub.other_taxable.length > 0 && (
            <Flex>
              <FlexRow
                className="mt-5 border-b-0.5 border-denim-20 h-7"
                verticalAlign="center"
                align="between"
              >
                <Text type="xs-medium" color="navy-light-100">
                  Other Taxable
                </Text>
                <Text type="xs-medium" color="denim-50">
                  Earnings
                </Text>
              </FlexRow>

              {paystub.other_taxable.map((value, index) => {
                return (
                  <FlexRow
                    className="h-6"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="xs-medium" color="denim-50">
                      {value.label}
                    </Text>
                    <Text type="xs-medium" color="denim-100">
                      ${((value.amount * 100) / 100).toFixed(2)}
                    </Text>
                  </FlexRow>
                );
              })}

              <FlexRow className="h-6" verticalAlign="center" align="between">
                <Text type="xs-medium" color="navy-light-100">
                  Total
                </Text>
                <Text type="xs-medium" color="navy-light-100">
                  $
                  {(
                    (paystub.other_taxable.reduce(
                      (n, { amount }) => n + amount,
                      0
                    ) *
                      100) /
                    100
                  ).toFixed(2)}
                </Text>
              </FlexRow>
            </Flex>
          )}

          {paystub.withholdings.length > 0 && (
            <Flex>
              <FlexRow
                className="mt-5 border-b-0.5 border-denim-20 h-7"
                verticalAlign="center"
                align="between"
              >
                <Text type="xs-medium" color="coral-100">
                  Withholdings
                </Text>
                <Text type="xs-medium" color="denim-50">
                  Earnings
                </Text>
              </FlexRow>

              {paystub.withholdings.map((value, index) => {
                return (
                  <FlexRow
                    className="h-6"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="xs-medium" color="denim-50">
                      {value.label}
                    </Text>
                    <Text type="xs-medium" color="denim-100">
                      ${((value.amount * 100) / 100).toFixed(2)}
                    </Text>
                  </FlexRow>
                );
              })}

              <FlexRow className="h-6" verticalAlign="center" align="between">
                <Text type="xs-medium" color="coral-100">
                  Total
                </Text>
                <Text type="xs-medium" color="coral-100">
                  $
                  {(
                    (paystub.withholdings.reduce(
                      (n, { amount }) => n + amount,
                      0
                    ) *
                      100) /
                    100
                  ).toFixed(2)}
                </Text>
              </FlexRow>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex className="flex-1">
      <SubContentTabs
        values={['2023', '2024']}
        align="start"
        defaultSelected={selectedTab}
        onSelected={(value) => setSelectedTab(value)}
      />
      <ScrollView
        className="flex-1 px-5"
        style={{ paddingBottom: insets.bottom == 0 ? 20 : insets.bottom }}
      >
        {renderPaystub()}
      </ScrollView>
    </Flex>
  );
}
