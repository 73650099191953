import { ActivityIndicator, ScrollView, TouchableOpacity } from 'react-native';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import React, { useState } from 'react';
import TextInput from '@components/Forms/TextInput';
import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import { transformTailwindToHex } from '@src/helpers';
import Upload from '@assets/icons/upload.svg';
import * as ImagePicker from 'expo-image-picker';
import FloatingBottom from '@components/Views/FloatingBottom';

type Step4Props = {
  productionId: string;
  nextStep: () => void;
};
export default function Step4({ productionId, nextStep }: Step4Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const pickDocument = async () => {
    let photo = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
      base64: false,
    });
  };

  return (
    <>
      <Flex className="flex-1 mt-5" verticalAlign="between">
        <ScrollView className="flex-1 px-5">
          <Flex className="flex-1">
            {loading && <ActivityIndicator className="mt-5" />}

            <TextInput
              label="Add Note"
              value={note}
              onChange={setNote}
              bigText={true}
            />

            <Text type="xs-medium" color="navy-100" className="mb-2">
              Additional Documents
            </Text>

            <TouchableOpacity onPress={() => null}>
              <FlexRow
                className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-navy-light-100/5"
                align="between"
                verticalAlign="center"
              >
                <Text type="sm-medium" color="navy-light-100">
                  parking_validation_receipt.png
                </Text>
                <Text type="xs-medium" color="navy-light-100">
                  Edit
                </Text>
              </FlexRow>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => pickDocument()}>
              <FlexRow
                className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20"
                align="center"
                verticalAlign="center"
              >
                <Upload
                  width={16}
                  height={16}
                  fill={transformTailwindToHex('denim-50')}
                />
                <Text type="xs-medium" color="denim-50" className="ml-2">
                  Add Document
                </Text>
              </FlexRow>
            </TouchableOpacity>
          </Flex>
        </ScrollView>
        <FloatingBottom>
          <Button
            useFullColor={true}
            value="Proceed to Final Review"
            color="navy-light-100"
            className="mt-2"
            onPress={() => nextStep()}
          />
        </FloatingBottom>
      </Flex>
    </>
  );
}
