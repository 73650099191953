import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, TouchableOpacity, View, ScrollView } from 'react-native';
import Flex from '@components/Views/Flex';

import CheckCircle from '@assets/icons/check-circle.svg';
import CheckSquare from '@assets/icons/check-square.svg';
import AlertCircle from '@assets/icons/alert-circle.svg';

import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import RBSheet from 'react-native-raw-bottom-sheet';
import {
  formatDateDefault,
  formatDateDefaultYearDayTypes,
  transformTailwindToHex,
} from '@src/helpers';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';
import productionApi from '@api/productionApi';

const { height } = Dimensions.get('window');

interface TimeCardRowProps {
  label: string;
  value: string[];
  disabled: boolean;
  updateValue: (value: any) => void;
  productionId: string;
}

export default function RentalDates({
  label,
  value,
  disabled,
  updateValue,
  productionId,
}: TimeCardRowProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [datesAvailable, setDatesAvailable] = useState<[]>([]);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const refRBSheet = useRef();

  useEffect(() => {
    getProduction();
    setSelectedDates(value);
  }, []);

  const getProduction = async () => {
    setLoading(true);

    let response = await productionApi.getProduction(productionId);

    response.data.related.shoots.data.forEach((shoot: any) => {
      setDatesAvailable((data) => [...data, shoot.attributes]);
    });

    setLoading(false);
  };

  const updateDatesAvailable = (date: string) => {
    if (!selectedDates.includes(date)) {
      setSelectedDates((data) => [...data, date]);
      updateValue([...selectedDates, date]);
      return;
    }

    setSelectedDates((data) => data.filter((item) => item !== date));
    updateValue(selectedDates.filter((item) => item !== date));
  };

  const getStringTime = () => {
    return `${formatDateDefault(selectedDates[0])} - ${formatDateDefault(
      selectedDates[selectedDates.length - 1]
    )}`;
  };

  const renderContent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          refRBSheet.current.open();
        }}
        disabled={disabled}
      >
        <FlexRow
          verticalAlign="center"
          align="between"
          className="w-full p-3 h-12 rounded-xl text-sm mb-4 border border-denim-20 placeholder-denim-50"
        >
          {selectedDates.length === 0 && (
            <Text type="sm-medium" color="denim-50">
              {label}
            </Text>
          )}
          {selectedDates.length > 0 && (
            <Text type="sm-medium" color="denim-50">
              {getStringTime()}
            </Text>
          )}

          {selectedDates.length === 0 && (
            <Flex align="end">
              <AlertCircle
                width={16}
                height={16}
                fill={transformTailwindToHex('coral-100')}
              />
            </Flex>
          )}

          {selectedDates.length > 0 && (
            <Flex align="end">
              <CheckCircle
                width={16}
                height={16}
                fill={transformTailwindToHex('navy-light-100')}
              />
            </Flex>
          )}
        </FlexRow>
      </TouchableOpacity>
    );
  };

  const renderInputs = () => {
    return (
      <>
        {datesAvailable.map((date: any, index: number) => {
          return (
            <TouchableOpacity
              onPress={() => updateDatesAvailable(date.date)}
              className="border-b border-denim-20"
            >
              <FlexRow key={index} className="gap-x-2" verticalAlign="center">
                {!selectedDates.includes(date.date) && (
                  <View className="w-5 h-5 border border-denim-100 rounded" />
                )}
                {selectedDates.includes(date.date) && (
                  <View className="w-5 h-5">
                    <CheckSquare width={20} height={20} fill="#FFF" />
                  </View>
                )}
                <Flex className="p-3 py-5 h-full gap-y-2" align="start">
                  <Text type="xs-semibold" color="navy-light-100">
                    {formatDateDefaultYearDayTypes(date.date)}
                  </Text>
                  <Text type="xs-medium" color="denim-100/60">
                    {date.location}
                  </Text>
                </Flex>
              </FlexRow>
            </TouchableOpacity>
          );
        })}
      </>
    );
  };

  return (
    <Flex>
      {renderContent()}
      <RBSheet
        ref={refRBSheet}
        animationType={'fade'}
        height={height < 500 ? height * 0.9 : 500}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="flex-1 p-8" verticalAlign="space-between">
          <Flex className="flex-grow w-full">
            <Text type="sm-semibold" color="navy-100" className="mb-5">
              {label}
            </Text>

            <ScrollView>
              {renderInputs()}

              <FlexRow
                verticalAlign="center"
                align="between"
                className="w-full"
              >
                <Text type="sm-medium" color="denim-50" className="my-5">
                  Days Selected
                </Text>
                <Text
                  type="sm-medium"
                  color={
                    selectedDates.length === 0 ? 'coral-100' : 'navy-light-100'
                  }
                  className="my-5"
                >
                  {selectedDates.length} Days
                </Text>
              </FlexRow>
            </ScrollView>
          </Flex>
          <Flex className="gap-y-2">
            <SecondaryButton
              value="Close"
              onPress={() => refRBSheet.current.close()}
            />
          </Flex>
        </Flex>
      </RBSheet>
    </Flex>
  );
}
