import React, { useRef, useState } from 'react';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import Flex from '@components/Views/Flex';

import CheckCircle from '@assets/icons/check-circle.svg';
import AlertCircle from '@assets/icons/alert-circle.svg';
import LockCircle from '@assets/icons/lock-circle.svg';
import CircleDashed from '@assets/icons/circle-dashed.svg';

import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import RBSheet from 'react-native-raw-bottom-sheet';
import Button from '@components/Buttons/Button';
import productionApi from '@api/productionApi';
import {
  formatTimecardtime,
  isEmpty,
  transformTailwindToHex,
  validateMealTimeSetCorrectly,
  validateTime,
} from '@src/helpers';
import { ShootType } from '@interfaces/ProductionInterface';
import { showMessage } from 'react-native-flash-message';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';
import TimeInput from '@components/Forms/TimeInput';
import moment from 'moment';

const { height } = Dimensions.get('window');

interface DailyMealRowProps {
  label: string;
  value?: {
    start_time: string;
    end_time: string;
    penalty: boolean;
  };
  disabled: boolean;
  required: boolean;
  locked: boolean;
  shootId: string;
  approved: boolean;
  type: string;
  completedBy: null | string;
  date: string;
  updateTimeCard: (shoot: ShootType) => void;
}

export function DailyMealRow({
  label,
  value,
  disabled,
  required,
  locked,
  shootId,
  type,
  approved,
  completedBy,
  updateTimeCard,
  date,
}: DailyMealRowProps) {
  const [timeOut, setTimeOut] = useState<string | null>(null);
  const [timeIn, setTimeIn] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTimeOutType, setSelectedTimeOutType] = useState<string>('');
  const [selectedTimeInType, setSelectedTimeInType] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('taken');
  const refRBSheet = useRef<RBSheet>(null);

  const usePreDefinedValue = async () => {
    setLoading(true);
    let response = await productionApi.postShootTimecard(shootId, {
      [type]: {
        penalty: true,
      },
    });

    if (response?.data != null) {
      updateTimeCard(response.data);
    }

    setLoading(false);
    refRBSheet.current?.close();
  };

  const save = async () => {
    // @TODO: refact!!!

    if (selectedType == 'skipped') {
      usePreDefinedValue();
      return;
    }

    const timecardTimeOut = validateTime(timeOut, selectedTimeOutType);
    const timecardTimeIn = validateTime(timeIn, selectedTimeInType);

    if (
      timecardTimeOut == null ||
      timecardTimeIn == null ||
      !validateMealTimeSetCorrectly(timecardTimeOut, timecardTimeIn)
    ) {
      showMessage({
        message: 'Invalid Time',
        description: 'Please enter a valid time',
        type: 'danger',
      });

      return;
    }

    setLoading(true);

    let response = await productionApi.postShootTimecard(shootId, {
      [type]: {
        start_time: timecardTimeOut,
        end_time: timecardTimeIn,
        penalty: false,
      },
    });

    if (response?.data != null) {
      updateTimeCard(response.data);
    }

    setLoading(false);
    refRBSheet.current?.close();
  };

  const getButtonFontColor = () => {
    if (moment().isBefore(moment(date))) {
      return 'denim-50';
    }

    if (locked && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100';
    }

    if (completedBy != null && completedBy !== 'Self') {
      return 'grape-100';
    }

    return 'white/100';
  };

  const getButtonBackgroundColor = () => {
    if (moment().isBefore(moment(date))) {
      return 'denim-10';
    }

    if (locked && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100/10';
    }

    if (completedBy != null && completedBy !== 'Self') {
      return 'grape-100/10';
    }

    if (!locked && value && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100';
    }

    if (!locked && !value && required) {
      return 'coral-100';
    }

    return 'denim-50';
  };

  const getStatus = () => {
    if (approved) {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="emerald-100">
          Approved
        </Text>
      );
    }

    if (!approved && locked && completedBy != null && completedBy == 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="navy-light-100">
          Admin Confirmed
        </Text>
      );
    }

    if (!approved && locked && completedBy != null && completedBy !== 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="grape-100">
          Admin Updated
        </Text>
      );
    }

    if (!approved && !locked && completedBy != null && completedBy !== 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="grape-100">
          Admin Updated
        </Text>
      );
    }

    if (!required && !value) {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="denim-50">
          Optional
        </Text>
      );
    }

    if (!locked && value && completedBy != null && completedBy == 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="navy-light-100">
          Complete
        </Text>
      );
    }

    if (required && !value)
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="coral-100">
          Required
        </Text>
      );

    return (
      <Text type="xs-medium" className="w-full ml-3.5" color="denim-50">
        Not Active Yet
      </Text>
    );
  };

  const getButtonValue = () => {
    if (moment().isBefore(moment(date))) {
      return 'Locked';
    }

    if (value?.penalty) return 'Skipped';

    if (value) {
      return `${moment(value?.start_time)
        .parseZone()
        .format('h:mma')
        .replace('m', '')} → ${moment(value?.end_time)
        .parseZone()
        .format('h:mma')
        .replace('m', '')}`;
    }

    return 'Add';
  };

  const renderContent = () => {
    return (
      <View
        className={`flex flex-row border-b py-5 px-6 border-gray-300 justify-between ${
          required && !value && !moment().isBefore(moment(date))
            ? 'bg-coral-100/10'
            : 'bg-white'
        }`}
      >
        <FlexRow align="between" verticalAlign="center" className="w-full">
          <Flex className="w-1/2">
            <FlexRow verticalAlign="center">
              {approved && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('emerald-100')}
                />
              )}

              {!approved && locked && !completedBy === null && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('denim-50')}
                />
              )}

              {!approved &&
                locked &&
                completedBy != null &&
                completedBy == 'Self' && (
                  <LockCircle
                    width={20}
                    height={20}
                    fill={transformTailwindToHex('navy-light-100')}
                  />
                )}

              {locked && completedBy != null && completedBy !== 'Self' && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('grape-100')}
                />
              )}

              {moment().isBefore(moment(date)) && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('denim-50')}
                />
              )}

              {required && !value && !moment().isBefore(moment(date)) && (
                <AlertCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('coral-100')}
                />
              )}

              {!locked &&
                value &&
                completedBy != null &&
                completedBy == 'Self' && (
                  <CheckCircle
                    width={20}
                    height={20}
                    fill={transformTailwindToHex('navy-light-100')}
                  />
                )}

              {!locked && completedBy != null && completedBy !== 'Self' && (
                <CheckCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('grape-100')}
                />
              )}

              {!approved &&
                !required &&
                !value &&
                !moment().isBefore(moment(date)) && (
                  <CircleDashed
                    width={20}
                    height={20}
                    fill={transformTailwindToHex('denim-50')}
                  />
                )}
              <Flex>
                <Text type="sm-semibold" className="w-full ml-3.5">
                  {label}
                </Text>
                {getStatus()}
              </Flex>
            </FlexRow>
          </Flex>
          <Flex className="w-1/2">
            <Button
              useFullColor={true}
              value={getButtonValue()}
              className="rounded-sm w-full"
              weight="sm-medium"
              disabled={
                locked ||
                (completedBy != null && completedBy != 'Self') ||
                moment().isBefore(moment(date))
              }
              color={getButtonBackgroundColor()}
              fontColor={getButtonFontColor()}
              onPress={() => {
                setTimeOut(null);
                setTimeIn(null);
                setSelectedTimeOutType('');
                setSelectedTimeInType('');
                setSelectedType('taken');

                refRBSheet.current?.open();
              }}
            />
          </Flex>
        </FlexRow>
      </View>
    );
  };

  const renderDisabledInputs = () => {
    return (
      <>
        <Flex className="opacity-50 my-5">
          <Text type="xs-medium" color="navy-100">
            Meal Out
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <Flex
              className="flex-1 border rounded-xl h-11 border-denim-20 mr-2 text-center text-[15px] font-medium"
              align="center"
              verticalAlign="center"
            >
              <Text type="sm-medium" color="denim-50">
                00:00
              </Text>
            </Flex>
            <FlexRow align="space-between" verticalAlign="center">
              <Flex
                className="flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0"
                verticalAlign="center"
                align="center"
              >
                <Text type="sm-medium" color="denim-50">
                  AM
                </Text>
              </Flex>
              <Flex
                className="flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1"
                verticalAlign="center"
                align="center"
              >
                <Text type="sm-medium" color="denim-50">
                  PM
                </Text>
              </Flex>
            </FlexRow>
          </FlexRow>
        </Flex>

        <Flex className="opacity-50 my-5">
          <Text type="xs-medium" color="navy-100">
            Meal In
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <Flex
              className="flex-1 border rounded-xl h-11 border-denim-20 mr-2 text-center text-[15px] font-medium"
              align="center"
              verticalAlign="center"
            >
              <Text type="sm-medium" color="denim-50">
                00:00
              </Text>
            </Flex>
            <FlexRow align="space-between" verticalAlign="center">
              <Flex
                className="flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0"
                verticalAlign="center"
                align="center"
              >
                <Text type="sm-medium" color="denim-50">
                  AM
                </Text>
              </Flex>
              <Flex
                className="flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1"
                verticalAlign="center"
                align="center"
              >
                <Text type="sm-medium" color="denim-50">
                  PM
                </Text>
              </Flex>
            </FlexRow>
          </FlexRow>
        </Flex>
      </>
    );
  };
  const renderInputs = () => {
    return (
      <>
        <Flex className="my-5">
          <Text type="xs-medium" color="navy-100">
            Meal Out
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <TimeInput
              label="Select Time"
              value={timeOut}
              onChange={setTimeOut}
            />
            <FlexRow
              align="space-between"
              verticalAlign="center"
              className="ml-2"
            >
              <TouchableOpacity
                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${
                  selectedTimeOutType == 'am' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedTimeOutType('am')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeOutType == 'am'
                        ? 'white/100'
                        : !isEmpty(selectedTimeOutType)
                        ? 'denim-50'
                        : 'coral-100'
                    }
                  >
                    AM
                  </Text>
                </Flex>
              </TouchableOpacity>
              <TouchableOpacity
                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${
                  selectedTimeOutType == 'pm' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedTimeOutType('pm')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeOutType == 'pm'
                        ? 'white/100'
                        : !isEmpty(selectedTimeOutType)
                        ? 'denim-50'
                        : 'coral-100'
                    }
                  >
                    PM
                  </Text>
                </Flex>
              </TouchableOpacity>
            </FlexRow>
          </FlexRow>
        </Flex>

        <Flex className="my-5">
          <Text type="xs-medium" color="navy-100">
            Meal In
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <TimeInput
              label="Select Time"
              value={timeIn}
              onChange={setTimeIn}
            />
            <FlexRow
              align="space-between"
              verticalAlign="center"
              className="ml-2"
            >
              <TouchableOpacity
                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${
                  selectedTimeInType == 'am' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedTimeInType('am')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeInType == 'am'
                        ? 'white/100'
                        : !isEmpty(selectedTimeInType)
                        ? 'denim-50'
                        : 'coral-100'
                    }
                  >
                    AM
                  </Text>
                </Flex>
              </TouchableOpacity>
              <TouchableOpacity
                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${
                  selectedTimeInType == 'pm' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedTimeInType('pm')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeInType == 'pm'
                        ? 'white/100'
                        : !isEmpty(selectedTimeInType)
                        ? 'denim-50'
                        : 'coral-100'
                    }
                  >
                    PM
                  </Text>
                </Flex>
              </TouchableOpacity>
            </FlexRow>
          </FlexRow>
        </Flex>
      </>
    );
  };

  return (
    <Flex>
      {renderContent()}
      {/* @ts-ignore */}
      <RBSheet
        ref={refRBSheet}
        animationType={'fade'}
        height={height < 500 ? height * 0.9 : 500}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              // @ts-ignore
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              // @ts-ignore
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="flex-1 p-8" verticalAlign="space-between">
          <Flex className="flex-grow gap-y-5">
            <Text type="sm-semibold" color="navy-100">
              {label}
            </Text>

            <FlexRow
              align="space-between"
              verticalAlign="center"
              className="my-5"
            >
              <TouchableOpacity
                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${
                  selectedType == 'taken' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedType('taken')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={selectedType == 'taken' ? 'white/100' : 'denim-50'}
                  >
                    Meal Taken
                  </Text>
                </Flex>
              </TouchableOpacity>
              <TouchableOpacity
                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${
                  selectedType == 'skipped' ? 'bg-navy-light-100' : ''
                }`}
                onPress={() => setSelectedType('skipped')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={selectedType == 'skipped' ? 'white/100' : 'denim-50'}
                  >
                    Meal Skipped
                  </Text>
                </Flex>
              </TouchableOpacity>
            </FlexRow>

            {selectedType == 'taken' && renderInputs()}
            {selectedType == 'skipped' && renderDisabledInputs()}
          </Flex>
          <Flex className="gap-y-2">
            <SecondaryButton
              value="Cancel"
              onPress={() => refRBSheet.current?.close()}
            />
            <Button
              value="Save"
              onPress={() => save()}
              useFullColor={true}
              loading={loading}
              disabled={
                (isEmpty(selectedTimeOutType) ||
                  isEmpty(timeOut) ||
                  isEmpty(selectedTimeInType) ||
                  isEmpty(timeIn)) &&
                selectedType == 'taken'
              }
            />
          </Flex>
        </Flex>
      </RBSheet>
    </Flex>
  );
}
