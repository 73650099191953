import React, { Fragment } from 'react';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { TouchableOpacity } from 'react-native-gesture-handler';

type CrewPoliciesProps = {
  policies: { title: string; policy: string }[];
  onOpenPolicy: (policy: string) => void;
};

export const CrewPolicies = ({ policies, onOpenPolicy }: CrewPoliciesProps) => {
  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5">
        Crew Policies
      </Text>

      <Flex className="bg-slate-800/5 py-4">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Any other policies associated with this role.
        </Text>
      </Flex>

      <Flex className="px-6">
        {policies.map((policy) => (
          <FlexRow
            key={policy.title}
            verticalAlign="center"
            align="between"
            className="h-11 border-b-0.5 border-denim-20"
          >
            <Text type="sm-medium" color="denim-50">
              {policy.title}
            </Text>
            <Text type="sm-medium" color="slate-800">
              <TouchableOpacity onPress={() => onOpenPolicy(policy.title)}>
                <Text type="xs-medium" color="navy-light-100">
                  View
                </Text>
              </TouchableOpacity>
            </Text>
          </FlexRow>
        ))}
      </Flex>
    </Fragment>
  );
};
