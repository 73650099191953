import { useFocusEffect } from '@react-navigation/native';
import productionApi from '@src/api/productionApi';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import { productionType } from '@src/interfaces/ProductionInterface';
import { useCallback, useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { ActivityIndicator } from 'react-native';
import { WeeklyView } from './components/WeeklyView/WeeklyView';
import moment from 'moment';
import { DailyView } from './components/DailyView/DailyView';

export type Time = {
  id: string;
  date: string;
  is_locked: boolean;
  checkin_time: string;
  checkout_time: string;
  required_meals_count: number;
  checkin_by?: string;
  checkout_by?: string;
  meal1: string | null;
  meal2: string | null;
  work_duration: number;
};

export type TimecardAttributes = {
  id: string;
  start_date: string;
  end_date: string;
  days_count: number;
  incomplete_days_count: number;
  is_approved: boolean;
  approved_at: string;
  status: 'approved' | 'scheduled' | 'active';
  times: Time[];
  total_duration: number;
};

type WorkScheduleProps = {
  production: productionType;
  timecardId?: string;
  tab?: string;
};

type RentalDetails = {
  rate_per_day: string;
  rental_dates: string;
};

type RentalAttributes = {
  type: string;
  amount: number;
  date: string;
  note: string;
  details: RentalDetails;
  status: string;
  file: null | string;
};

export type Bonus = {
  id: string;
  attributes: RentalAttributes;
  related: any[];
};

export type Timecard = {
  id: string;
  attributes: TimecardAttributes;
  related: {
    bonuses: {
      data: Bonus[];
    };
  };
};

const VIEWS = {
  WORK_SCHEDULE: 'work-schedule',
  WEEKLY_VIEW: 'weekly-view',
  DAILY_VIEW: 'daily-view',
};

export const WorkSchedule = ({
  production,
  timecardId,
  tab,
}: WorkScheduleProps) => {
  const [timecard, setTimecard] = useState<Timecard | null>(null);
  const [day, setDay] = useState<Time | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timecardList, setTimecardList] = useState<Timecard[]>([]);
  const [view, setView] = useState(VIEWS.DAILY_VIEW);

  useFocusEffect(
    useCallback(() => {
      getTimecardList();
    }, [])
  );

  useEffect(() => {
    if (timecardId) {
      getTimecardById();
    }
  }, [timecardId]);

  const getTimecardById = async () => {
    const response = await productionApi.getTimecard(timecardId!);

    setTimecard(response.data);

    setView(VIEWS.WEEKLY_VIEW);
  };

  const getTimecardList = async () => {
    setLoading(true);
    let response: {
      data: Timecard[];
    } = await productionApi.getTimecardList(
      `&filters[production]=${production.id}`
    );

    const ids = response.data.map((timecard) => timecard.id);

    const promises = ids.map((id) => productionApi.getTimecard(id));

    const details: { data: Timecard }[] = await Promise.all(promises);

    setTimecardList(details.map((response) => response.data));

    setLoading(false);
  };

  if (loading) {
    return (
      <Flex className="flex-1">
        <ActivityIndicator className="mt-5" />
      </Flex>
    );
  }

  if (timecardList.length == 0) {
    return (
      <Flex className="flex-1 pt-5" align="center">
        <Text type="sm-semibold" color="denim-50">
          No data to display
        </Text>
      </Flex>
    );
  }

  const status = {
    approved: (
      <Text type="sm-semibold" color="emerald-100">
        Wrapped
      </Text>
    ),
    progress: (
      <Text type="sm-semibold" color="navy-light-100">
        In Progress
      </Text>
    ),
    action: (
      <Text type="sm-semibold" color="coral-100">
        Action Required
      </Text>
    ),
    scheduled: (
      <Text type="sm-semibold" color="denim-50">
        Scheduled
      </Text>
    ),
  };

  const getCurrentStatus = (timecard: Timecard) => {
    const items = timecard?.attributes.times || [];

    const hasInvalidItem = () =>
      items.some((item) => {
        const meal1Required = item.required_meals_count >= 1;
        const meal2Required = item.required_meals_count === 2;

        if (
          (!item.checkin_time || !item.checkout_time) &&
          moment(item.date).isBefore(moment())
        ) {
          return true;
        }

        if (!item.checkin_time || !item.checkout_time) {
          return true;
        }

        if (meal1Required && !item.meal1) {
          return true;
        }

        if (meal2Required && !item.meal2) {
          return true;
        }

        return false;
      });

    if (loading) {
      return (
        <Text type="sm-semibold" color="denim-50">
          {' '}
        </Text>
      );
    }

    if (timecard.attributes.is_approved) {
      return status['approved'];
    }

    if (timecard.attributes.status === 'scheduled') {
      return status['scheduled'];
    }

    if (
      hasInvalidItem() ||
      (timecard.attributes.approved_at == null &&
        timecard.attributes.incomplete_days_count == 0)
    ) {
      return status['action'];
    }

    if (timecard.attributes.status === 'active') {
      return status['progress'];
    }

    return status['scheduled'];
  };

  if (view === VIEWS.DAILY_VIEW && day && timecard) {
    return (
      <DailyView
        day={day}
        timecardId={timecard.id}
        onChangeDay={setDay}
        onBackToWeekly={() => setView(VIEWS.WEEKLY_VIEW)}
      />
    );
  }

  if (view === VIEWS.WEEKLY_VIEW && timecard) {
    return (
      <WeeklyView
        production={production}
        timecard={timecard}
        title={`Week ${
          timecardList.findIndex((card) => card.id === timecard.id) + 1
        } of ${timecardList.length}`}
        timecards={timecardList}
        onChange={(timecard) => setTimecard(timecard)}
        subtab={tab}
        onApproveReview={getTimecardById}
        onDayChose={(day) => {
          setView(VIEWS.DAILY_VIEW);
          setDay(day);
        }}
      />
    );
  }

  return (
    <ScrollView>
      <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Select any week from the list below to submit your timecards and
          payments for that pay period.
        </Text>
      </Flex>
      <Flex className="w-full">
        {timecardList.map((timecard) => {
          const items = timecard?.attributes.times || [];

          const hasInvalidItem = () =>
            items.some((item) => {
              const meal1Required = item.required_meals_count >= 1;
              const meal2Required = item.required_meals_count === 2;

              if (item.checkin_time && item.checkout_time) {
                return false;
              }

              if (meal1Required && item.meal1) {
                return false;
              }

              if (meal2Required && item.meal2) {
                return false;
              }

              return true;
            });

          return (
            <TouchableOpacity
              key={timecard.id}
              className={`flex flex-row border-b py-5 px-6 border-gray-300 justify-between ${
                moment(timecard.attributes?.start_date).isSameOrBefore(
                  moment()
                ) &&
                (hasInvalidItem() ||
                  (timecard.attributes.approved_at == null &&
                    timecard.attributes.incomplete_days_count == 0))
                  ? 'bg-coral-100/10'
                  : 'white/100'
              }`}
              onPress={() => {
                setTimecard(timecard);
                setView(VIEWS.WEEKLY_VIEW);
              }}
            >
              <FlexRow>
                <Text type="sm-semibold" className="w-36">
                  {moment(timecard.attributes.start_date).format('MMM DD')} -{' '}
                  {moment(timecard.attributes.end_date).format('MMM DD')}
                </Text>
                <Text type="sm-medium" color="denim-50">
                  {timecard.attributes.days_count} Days
                </Text>
              </FlexRow>
              {getCurrentStatus(timecard)}
            </TouchableOpacity>
          );
        })}
      </Flex>
    </ScrollView>
  );
};
