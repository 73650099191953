import { ActivityIndicator, ScrollView } from 'react-native';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import React, { useState } from 'react';
import SelectInput from '@components/Forms/SelectInput';
import FloatingBottom from '@components/Views/FloatingBottom';

type Step1Props = {
  productionId: string;
  nextStep: () => void;
};
export default function Step1({ productionId, nextStep }: Step1Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [payPeriod, setPayPeriod] = useState<string>('');

  return (
    <Flex className="flex-1 mt-5" verticalAlign="between">
      <ScrollView className="flex-1 px-5">
        <Flex className="flex-1">
          {loading && <ActivityIndicator className="mt-5" />}

          <SelectInput
            label="Select Pay Period"
            value={payPeriod}
            options={[]}
            onChange={() => null}
          />
        </Flex>
      </ScrollView>

      <FloatingBottom>
        <Button
          useFullColor={true}
          value="Next"
          color="navy-light-100"
          className="mt-2"
          onPress={() => nextStep()}
        />
      </FloatingBottom>
    </Flex>
  );
}
