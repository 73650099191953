import { useGlobal } from 'reactn';

import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Home from '@pages/SignedIn/Home';
import History from '@pages/SignedIn/History';
import Login from '@pages/SignedOut/Login';
import OfferSummary from '@pages/SignedOut/OfferSummary';
import { GlobalStateInterface } from 'reactn/default';

import * as Linking from 'expo-linking';
import HowItWorks from '@pages/SignedOut/HowItWorks';
import GettingStarted from '@pages/SignedOut/GettingStarted';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Production from '@pages/SignedIn/Production';
import Offer from '@pages/SignedIn/Offer';
import Onboarding from '@pages/SignedIn/Offer/Onboarding';
import SubmissionComplete from '@pages/SignedIn/Offer/SubmissionComplete';
import Settings from '@pages/SignedIn/Settings';
import ProductionPayment from '@pages/SignedIn/ProductionPayment';
import ProductionInvoice from '@pages/SignedIn/ProductionInvoice';
import ExtraCrewData from '@pages/SignedIn/Offer/ExtraCrewData';
import Text from './components/General/Text';
import { Paystub } from './pages/SignedIn/Production/Paystub/Paystub';

export type NonAuthStackParamList = {
  Login: undefined;
  OfferSummary: { id: string };
  HowItWorks: undefined;
  GettingStarted: undefined;
};

export type AuthStackParamList = {
  Home: undefined;
  Offer: { offerId: string };
  OfferOnboarding: {
    offerId: string;
    productionId: string;
    employment: string;
    requireI9: boolean;
  };
  OfferSubmissionComplete: { productionId: string };
  OfferExtraCrewData: {
    offerId: string;
    productionId: string;
    employment: string;
    requireI9: boolean;
  };
  Production: {
    productionId: string;
    tab?: string;
    subtab?: string;
    timecardId?: string;
  };
  ProductionPaystub: { productionId: string; id: string };
  ProductionPayment: { productionId: string; timecardId: string };
  ProductionInvoice: { productionId: string };
  History: { tab: string };
  Settings: { tab: string };
};

const AuthStack = createStackNavigator<AuthStackParamList>();
const NonAuthStack = createStackNavigator<NonAuthStackParamList>();

const prefix = Linking.createURL('/');
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      Login: 'login',
      OfferSummary: 'offer-summary/:id',
      HowItWorks: 'how-it-works',
      GettingStarted: 'getting-started',
      Home: 'home',
      Offer: 'offer/:offerId',
      OfferOnboarding: 'offer-onboarding/:offerId/:productionId/:employment',
      OfferSubmissionComplete: 'offer-submission-complete/:productionId',
      OfferExtraCrewData: 'offer-extra-crew-data',
      Production: 'production/:productionId/:tab',
      ProductionPaystub: 'production/:productionId/paystub/:id',
      ProductionPayment: 'production-payment/:productionId/:timecardId',
      ProductionInvoice: 'production-invoice/:productionId',
      History: 'history/:tab',
      Settings: 'settings/:tab',
    },
  },
};

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#FFFFFF',
  },
};

const renderStack = (access_token?: string) => {
  if (access_token == null) {
    return (
      <NonAuthStack.Navigator
        screenOptions={{ headerShown: false, animationEnabled: false }}
      >
        <NonAuthStack.Screen name="Login" component={Login} />
        <NonAuthStack.Screen name="GettingStarted" component={GettingStarted} />
        <NonAuthStack.Screen name="OfferSummary" component={OfferSummary} />
        <NonAuthStack.Screen name="HowItWorks" component={HowItWorks} />
      </NonAuthStack.Navigator>
    );
  }

  return (
    <AuthStack.Navigator
      screenOptions={{ headerShown: false, animationEnabled: false }}
    >
      <AuthStack.Screen name="Home" component={Home} />
      <AuthStack.Screen name="Offer" component={Offer} />
      <AuthStack.Screen name="OfferExtraCrewData" component={ExtraCrewData} />
      <AuthStack.Screen name="OfferOnboarding" component={Onboarding} />
      <AuthStack.Screen
        name="OfferSubmissionComplete"
        component={SubmissionComplete}
      />
      <AuthStack.Screen name="Production" component={Production} />
      <AuthStack.Screen
        name="ProductionPayment"
        component={ProductionPayment}
      />
      <AuthStack.Screen
        name="ProductionInvoice"
        component={ProductionInvoice}
      />
      <AuthStack.Screen name="ProductionPaystub" component={Paystub} />
      <AuthStack.Screen name="History" component={History} />
      <AuthStack.Screen name="Settings" component={Settings} />
    </AuthStack.Navigator>
  );
};

export default function Routes() {
  let [loading, setLoading] = useState(true);
  let [onboarding, setOnboarding] = useState(false);
  const [access_token, setAccessToken] =
    useGlobal<GlobalStateInterface>('access_token');
  const [initialState, setInitialState] = useState();

  const init = async () => {
    const asyncToken = await AsyncStorage.getItem('access_token');
    setAccessToken(asyncToken);

    const initialUrl = await Linking.getInitialURL();
    const navigationHistory = await AsyncStorage.getItem(
      'navigation_history_state'
    );
    const onboardingNavigationHistory = await AsyncStorage.getItem(
      'onboarding_navigation_history_state'
    );
    let state = null;

    if (onboarding && navigationHistory) {
      state = JSON.parse(onboardingNavigationHistory!);
    }

    if (
      !onboarding &&
      checkIfScreenMatchesStack(initialUrl, navigationHistory)
    ) {
      state = JSON.parse(navigationHistory);
    }

    if (state !== null) {
      setInitialState(state);
    }

    setLoading(false);
  };

  const checkIfScreenMatchesStack = (initialUrl, navigationHistory) => {
    if (!navigationHistory) {
      return false;
    }

    let history = JSON.parse(navigationHistory);
    let lastStateScreen = history.routes[history.index].name;

    let splitUrl = initialUrl.split('/');
    const camelToSnakeCase = lastStateScreen
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();

    return splitUrl.includes(camelToSnakeCase);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <NavigationContainer
      linking={linking}
      theme={navTheme}
      initialState={initialState}
      onStateChange={(state) => {
        AsyncStorage.setItem('navigation_history_state', JSON.stringify(state));

        if (onboarding) {
          AsyncStorage.setItem(
            'onboarding_navigation_history_state',
            JSON.stringify(state)
          );
        }
      }}
    >
      {renderStack(access_token)}
    </NavigationContainer>
  );
}
