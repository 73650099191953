import React, { useEffect, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import FlexRow from '@components/Views/FlexRow';
import Upload from '@assets/icons/upload.svg';
import TextInput from '@components/Forms/TextInput';
import DateInput from '@components/Forms/DateInput';
import CurrencyInputStyled from '@components/Forms/CurrencyInput';
import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import { toTwoDecimal, transformTailwindToHex } from '@src/helpers';
import * as DocumentPicker from 'expo-document-picker';

export default function Default({ data, onUpdateData }) {
  const [paymentDocument, setPaymentDocument] = useState<any>(null);
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
  const [paymentDate, setPaymentDate] = useState<string>('');
  const [paymentNotes, setPaymentNotes] = useState<string>('');

  useEffect(() => {
    if (data.amount !== undefined) {
      setPaymentAmount(data.amount);
    }

    if (data.date !== undefined) {
      setPaymentDate(data.date);
    }

    if (data.note !== undefined) {
      setPaymentNotes(data.note);
    }
  }, []);

  useEffect(() => {
    onUpdateData({
      amount: paymentAmount,
      date: paymentDate,
      note: paymentNotes,
      payment_document: paymentDocument,
    });
  }, [paymentDocument, paymentAmount, paymentDate, paymentNotes]);

  const pickDocument = async () => {
    let documentResult = await DocumentPicker.getDocumentAsync({
      type: '*/*',
      multiple: false,
      copyToCacheDirectory: true,
    });

    if (documentResult.type === 'cancel') {
      // User canceled picking a document, so just return
      return;
    }

    if (Platform.OS !== 'web') {
      setPaymentDocument({
        name: documentResult.name,
        // If mimeType is not available, default to a generic type.
        type: documentResult.mimeType
          ? documentResult.mimeType
          : 'application/octet-stream',
        uri:
          Platform.OS === 'ios'
            ? documentResult.uri.replace('file://', '')
            : documentResult.uri,
      });
    } else {
      // On web, we need to convert the file into a blob
      const res = await fetch(documentResult.uri);
      const blob = await res.blob();
      setPaymentDocument(blob);
    }
  };

  return (
    <Flex>
      <DateInput
        label="Transaction Date"
        value={paymentDate}
        defaultValue={paymentDate}
        onChange={setPaymentDate}
      />

      <Flex>
        <Flex className="absolute top-4 left-3">
          <Text type="xs-medium" color="denim-50">
            $
          </Text>
        </Flex>
        <CurrencyInputStyled
          label="Payment Amount"
          value={paymentAmount}
          onChange={setPaymentAmount}
          paddingLeft="pl-6"
        />
      </Flex>

      <TextInput
        label="Add Note"
        value={paymentNotes}
        onChange={setPaymentNotes}
        bigText={true}
      />

      <TouchableOpacity onPress={() => pickDocument()}>
        {paymentDocument == null && (
          <FlexRow
            className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-denim-5"
            align="center"
            verticalAlign="center"
          >
            <Upload
              width={16}
              height={16}
              fill={transformTailwindToHex('denim-100')}
            />
            <Text type="xs-medium" color="denim-100" className="ml-2">
              Add Document
            </Text>
          </FlexRow>
        )}

        {paymentDocument != null && (
          <FlexRow
            className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-navy-light-100/5"
            align="center"
            verticalAlign="center"
          >
            <Upload
              width={16}
              height={16}
              fill={transformTailwindToHex('navy-light-100')}
            />
            <Text type="xs-medium" color="navy-light-100" className="ml-2">
              Upload Complete!
            </Text>
          </FlexRow>
        )}
      </TouchableOpacity>

      <FlexRow verticalAlign="center" align="between" className="w-full">
        <Text type="sm-medium" color="denim-50" className="my-5">
          Total Payment:
        </Text>
        <Text
          type="sm-medium"
          color={paymentAmount == null ? 'coral-100' : 'navy-light-100'}
          className="my-5"
        >
          ${toTwoDecimal(paymentAmount ?? 0)}
        </Text>
      </FlexRow>
    </Flex>
  );
}
