import React, { useEffect, useState } from 'react';
import TextInput from '@components/Forms/TextInput';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import Header from '@components/Views/Header';
import AddressInput from '@components/Forms/AddressInput';
import { signUpData } from '@pages/SignedOut/GettingStarted';
import { maskNumberInput } from '@src/helpers';
import { onlyCharacters } from '@helpers/formatters';
import moment from 'moment';
import FloatingBottom from '@src/components/Views/FloatingBottom';
import SecondaryButton from '@src/components/Buttons/SecondaryButton';

type GettingStartedStep2Props = {
  data: signUpData;
  updateData: (data: {}) => void;
  loading: boolean;
};

export default function GettingStartedStep2(props: GettingStartedStep2Props) {
  const [birthdateInvalid, setBirthdateInvalid] = useState(false);
  const [underage, setUnderage] = useState(false);
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [homeAddress, setHomeAddress] = useState<object | string | null>(null);
  const [extra, setExtra] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [ssn, setSSN] = useState<string>('');
  const [secureSsn, setSecureSSN] = useState<string>('');

  useEffect(() => {
    updateDataBasedOnProps();
  }, []);

  const updateDataBasedOnProps = () => {
    setName(props.data.first_name ?? '');
    setLastName(props.data.last_name ?? '');
    setPhoneNumber(props.data.phone_number ?? '');
    setHomeAddress(props.data.address ?? null);
    setExtra(props.data.extra ?? '');
    setSSN(props.data.ssn ?? '');
    setBirthdate(props.data.birthdate ?? '');
  };

  const goToNextStep = () => {
    props.updateData({
      first_name: name,
      last_name: lastName,
      phone_number: phoneNumber,
      address: homeAddress,
      extra: extra,
      ssn: ssn,
      birthdate: moment(birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    });
  };

  const checkAllItemsNull = (obj) => {
    if (typeof obj !== 'object') {
      return false;
    }

    for (let key in obj) {
      if (obj[key] !== null && obj[key] !== "") {
        return false;
      }
    }

    return true
  }

  return (
    <Flex className="flex-1" verticalAlign="content-between">
      <Header title="Getting Started" showBack={false} />

      <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
        <Text type="sm-semibold" color="white/100">
          Setup Your FilmUp Account
        </Text>
        <Text type="xs-normal" className="opacity-70 pt-2" color="white/100">
          Confirm the email address and password that you would like to use to
          access your FilmUp account.
        </Text>
      </Flex>

      <Flex className="flex-1 bg-white" verticalAlign="between">
        <Flex className="flex-1 p-5 gap-y-2">
          <TextInput
            label="First Name"
            onChange={setName}
            value={name}
            formatter={onlyCharacters}
            readonly={true}
          />
          <TextInput
            label="Last Name"
            onChange={setLastName}
            value={lastName}
            formatter={onlyCharacters}
            readonly={true}
          />
          <TextInput
            label="Phone Number"
            onChange={setPhoneNumber}
            value={phoneNumber}
          />
          <AddressInput
            label="-"
            value={homeAddress as string}
            onChange={setHomeAddress}
          />
          <TextInput
            label="Home Address (Line 2)"
            onChange={setExtra}
            value={extra}
            required={false}
          />
          <TextInput
            label="SSN"
            keyboardType="numeric"
            onChange={(value) => {
              let maskedValue = maskNumberInput(
                value,
                '999-99-9999',
                true,
                ssn
              );

              setSSN(maskedValue.text);
              setSecureSSN(maskedValue.secureText);
            }}
            value={secureSsn}
          />
          <TextInput
            label="Date of Birth"
            value={birthdate}
            keyboardType="numeric"
            error={
              birthdateInvalid || birthdate.length != 10
                ? 'Invalid birthdate. Please enter a valid date (MM/DD/YYYY).'
                : ''
            }
            onChange={(value) => {
              let cleanedValue = value.replace(/\D/g, '');

              let formattedValue = '';

              if (cleanedValue.length > 0) {
                formattedValue = cleanedValue.slice(0, 2);

                if (Number(cleanedValue.slice(0, 2)) > 12) {
                  return;
                }
              }
              if (cleanedValue.length > 2) {
                formattedValue += '/' + cleanedValue.slice(2, 4);

                if (Number(cleanedValue.slice(2, 4)) > 31) {
                  return;
                }
              }
              if (cleanedValue.length > 4) {
                formattedValue += '/' + cleanedValue.slice(4, 8);
              }

              if (formattedValue.length === 10) {
                const isValidDate = moment(
                  formattedValue,
                  'MM/DD/YYYY',
                  true
                ).isValid();

                setBirthdateInvalid(false);

                if (!isValidDate) {
                  setBirthdateInvalid(true);
                }

                const birthdate = moment(formattedValue, 'MM/DD/YYYY');
                const today = moment();
                const age = today.diff(birthdate, 'years');

                if (age < 18) {
                  setUnderage(true);
                } else {
                  setUnderage(false);
                }
              }

              setBirthdate(formattedValue);
            }}
          />

          {birthdateInvalid && (
            <Text className="ml-2" color="coral-100" type="2xs-normal">
              Invalid birthdate. Please enter a valid date (MM/DD/YYYY).
            </Text>
          )}
        </Flex>

        <Flex className="rounded-3xl px-5 py-3 bg-white shadow-lg gap-y-2 pb-5">
          <Button
            useFullColor={true}
            value="Sign Up"
            className="mt-2"
            loading={props.loading}
            onPress={() => goToNextStep()}
            disabled={
              (name == '' ||
              lastName == '' ||
              phoneNumber == '' ||
              homeAddress == null ||
              homeAddress == "" ||
              (checkAllItemsNull(homeAddress)) ||
              ssn == '' ||
              birthdate === '' ||
              birthdateInvalid ||
              birthdate.length != 10)
            }
          />
        </Flex>
        {underage && (
          <FloatingBottom>
            <Flex verticalAlign="center" align="start">
              <Text type="sm-semibold" color="coral-100">
                IMPORTANT: Underage Crew Requirements
              </Text>

              <Text type="xs-medium" color="denim-50" className="my-5">
                The Date of Birth you provided identifies you as under 18 years
                of age - If this is correct you will be required to provide
                additional information.
              </Text>
            </Flex>
            <Button
              useFullColor={true}
              value="Proceed as Underage Crew"
              color="coral-100"
              onPress={() => setUnderage(false)}
            />
            <SecondaryButton
              className="mt-2.5"
              value="Back"
              onPress={() => {
                setBirthdate('');
                setUnderage(false);
              }}
            />
          </FloatingBottom>
        )}
      </Flex>
    </Flex>
  );
}
