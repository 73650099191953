import React, { useRef, useState } from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import Flex from '@components/Views/Flex';

import CheckCircle from '@assets/icons/check-circle.svg';
import AlertCircle from '@assets/icons/alert-circle.svg';

import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import RBSheet from 'react-native-raw-bottom-sheet';
import Button from '@components/Buttons/Button';
import {
  formatTimecardtime,
  isEmpty,
  transformTailwindToHex,
  validateMealTimeSetCorrectly,
  validateTime,
} from '@src/helpers';
import { showMessage } from 'react-native-flash-message';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';
import TimeInput from '@components/Forms/TimeInput';

const { height } = Dimensions.get('window');

interface TimeCardRowProps {
  label: string;
  value: {
    start_time: string;
    end_time: string;
  } | null;
  disabled: boolean;
  updateValue: (value: any) => void;
}

export default function TravelTime({
  label,
  value,
  disabled,
  updateValue,
}: TimeCardRowProps) {
  const [timeOut, setTimeOut] = useState<string | null>(null);
  const [timeIn, setTimeIn] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTimeOutType, setSelectedTimeOutType] = useState<string>('');
  const [selectedTimeInType, setSelectedTimeInType] = useState<string>('');
  const refRBSheet = useRef();

  const save = async () => {
    // @TODO: refact!!!

    const timecardTimeOut = validateTime(timeOut, selectedTimeOutType);
    const timecardTimeIn = validateTime(timeIn, selectedTimeInType);

    if (
      timecardTimeOut == null ||
      timecardTimeIn == null ||
      !validateMealTimeSetCorrectly(timecardTimeOut, timecardTimeIn)
    ) {
      showMessage({
        message: 'Invalid Time',
        description: 'Please enter a valid time',
        type: 'danger',
      });

      return;
    }

    setLoading(true);
    updateValue({
      start_time: timecardTimeOut,
      end_time: timecardTimeIn,
    });
    setLoading(false);

    refRBSheet.current.close();
  };

  const getStringTime = () => {
    return `${value?.start_time} - ${value?.end_time}`;
  };

  const renderContent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          setTimeOut(null);
          setTimeIn(null);
          setSelectedTimeOutType('');
          setSelectedTimeInType('');

          refRBSheet.current.open();
        }}
        disabled={disabled}
      >
        <FlexRow
          verticalAlign="center"
          align="between"
          className="w-full p-3 h-12 rounded-xl text-sm mb-4 border border-denim-20 placeholder-denim-50"
        >
          {value == null && (
            <Text type="sm-medium" color="denim-50">
              {label}
            </Text>
          )}
          {value != null && (
            <Text type="sm-medium" color="denim-50">
              {getStringTime()}
            </Text>
          )}

          {value == null && (
            <Flex align="end">
              <AlertCircle
                width={16}
                height={16}
                fill={transformTailwindToHex('coral-100')}
              />
            </Flex>
          )}

          {value != null && (
            <Flex align="end">
              <CheckCircle
                width={16}
                height={16}
                fill={transformTailwindToHex('navy-light-100')}
              />
            </Flex>
          )}
        </FlexRow>
      </TouchableOpacity>
    );
  };

  const renderInputs = () => {
    return (
      <>
        <Flex className="my-5">
          <Text type="xs-medium" color="navy-100">
            Start Time
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <TimeInput
              label="Select Time"
              value={timeOut}
              onChange={setTimeOut}
            />
            <FlexRow
              align="space-between"
              verticalAlign="center"
              className="ml-2"
            >
              <TouchableOpacity
                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedTimeOutType == 'am' ? 'bg-navy-light-100' : ''}`}
                onPress={() => setSelectedTimeOutType('am')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeOutType == 'am'
                        ? 'white'
                        : !isEmpty(selectedTimeOutType)
                          ? 'denim-50'
                          : 'coral-100'
                    }
                  >
                    AM
                  </Text>
                </Flex>
              </TouchableOpacity>
              <TouchableOpacity
                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedTimeOutType == 'pm' ? 'bg-navy-light-100' : ''}`}
                onPress={() => setSelectedTimeOutType('pm')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeOutType == 'pm'
                        ? 'white'
                        : !isEmpty(selectedTimeOutType)
                          ? 'denim-50'
                          : 'coral-100'
                    }
                  >
                    PM
                  </Text>
                </Flex>
              </TouchableOpacity>
            </FlexRow>
          </FlexRow>
        </Flex>

        <Flex className="my-5">
          <Text type="xs-medium" color="navy-100">
            End Time
          </Text>
          <FlexRow
            align="space-between"
            verticalAlign="center"
            className="mt-1"
          >
            <TimeInput
              label="Select Time"
              value={timeIn}
              onChange={setTimeIn}
            />
            <FlexRow
              align="space-between"
              verticalAlign="center"
              className="ml-2"
            >
              <TouchableOpacity
                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedTimeInType == 'am' ? 'bg-navy-light-100' : ''}`}
                onPress={() => setSelectedTimeInType('am')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeInType == 'am'
                        ? 'white'
                        : !isEmpty(selectedTimeInType)
                          ? 'denim-50'
                          : 'coral-100'
                    }
                  >
                    AM
                  </Text>
                </Flex>
              </TouchableOpacity>
              <TouchableOpacity
                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedTimeInType == 'pm' ? 'bg-navy-light-100' : ''}`}
                onPress={() => setSelectedTimeInType('pm')}
              >
                <Flex className="flex-1" verticalAlign="center" align="center">
                  <Text
                    type="sm-medium"
                    color={
                      selectedTimeInType == 'pm'
                        ? 'white'
                        : !isEmpty(selectedTimeInType)
                          ? 'denim-50'
                          : 'coral-100'
                    }
                  >
                    PM
                  </Text>
                </Flex>
              </TouchableOpacity>
            </FlexRow>
          </FlexRow>
        </Flex>
      </>
    );
  };

  return (
    <Flex>
      {renderContent()}
      <RBSheet
        ref={refRBSheet}
        animationType={'fade'}
        height={height < 500 ? height * 0.9 : 500}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="flex-1 p-8" verticalAlign="space-between">
          <Flex className="flex-grow gap-y-5">
            <Text type="sm-semibold" color="navy-100">
              {label}
            </Text>

            {renderInputs()}
          </Flex>
          <Flex className="gap-y-2">
            <SecondaryButton
              value="Cancel"
              onPress={() => refRBSheet.current.close()}
            />
            <Button
              value="Save"
              onPress={() => save()}
              useFullColor={true}
              loading={loading}
              disabled={
                isEmpty(selectedTimeOutType) ||
                isEmpty(timeOut) ||
                isEmpty(selectedTimeInType) ||
                isEmpty(timeIn)
              }
            />
          </Flex>
        </Flex>
      </RBSheet>
    </Flex>
  );
}
