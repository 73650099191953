import { productionType } from '@src/interfaces/ProductionInterface';
import { Fragment } from 'react';
import * as Clipboard from 'expo-clipboard';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Flex from '../Views/Flex';
import FlexRow from '../Views/FlexRow';
import Text from './Text';

type YourProductionContactProps = {
  production: productionType;
};

export const YourProductionContact = ({
  production,
}: YourProductionContactProps) => {
  const info = [
    {
      title: 'Name',
      value: production.attributes.contact.name || '-',
      copy: false,
    },
    {
      title: 'Title',
      value: production.attributes.contact.title || '-',
      copy: false,
    },
    {
      title: 'Email',
      value: production.attributes.contact.email || '-',
      copy: true,
    },
    {
      title: 'Phone',
      value: production.attributes.contact.phone_number || '-',
      copy: true,
    },
  ];

  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5 mt-10">
        Your Production Contact
      </Text>

      <Flex className="bg-slate-800/5 py-4">
        <Text type="xs-medium" color="denim-50" className="px-6">
          For questions about your timecard, work schedule, or any other
          production questions, please reach out to:{' '}
        </Text>
      </Flex>

      <Flex className="px-6">
        {info.map((info) => (
          <FlexRow
            key={info.title}
            verticalAlign="center"
            align="between"
            className="h-11 border-b-0.5 border-denim-20"
          >
            <FlexRow>
              <Text type="sm-medium" color="denim-50" className="w-16">
                {info.title}
              </Text>
              {info.copy && info.value !== '-' && (
                <TouchableOpacity
                  onPress={async () => {
                    await Clipboard.setStringAsync(info.value);
                  }}
                >
                  <Text color="navy-light-100">Copy</Text>
                </TouchableOpacity>
              )}
            </FlexRow>
            <Text type="sm-medium" color="slate-800">
              {info.value}
            </Text>
          </FlexRow>
        ))}
      </Flex>
    </Fragment>
  );
};
