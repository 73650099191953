import { View } from 'react-native';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import { Time, TimecardAttributes } from '../../../WorkSchedule';
import { isEmpty, transformTailwindToHex } from '@src/helpers';
import LockCircle from '@assets/icons/lock-circle.svg';
import { TouchableOpacity } from 'react-native-gesture-handler';
import FloatingBottom from '@src/components/Views/FloatingBottom';
import SecondaryButton from '@src/components/Buttons/SecondaryButton';
import Button from '@src/components/Buttons/Button';
import { useFocusEffect } from '@react-navigation/native';
import React, { useState } from 'react';
import { getElapsedHours } from '@src/helpers/get-elapsed-hours';
import moment from 'moment';

type TimecardsProps = {
  timecard: TimecardAttributes;
  onReview: () => void;
  onDayChose: (day: Time) => void;
};

export const Timecards = ({
  timecard,
  onDayChose,
  onReview,
}: TimecardsProps) => {
  const [showDonePopup, setShowDonePopup] = useState<boolean>(true);

  useFocusEffect(
    React.useCallback(() => {
      setShowDonePopup(true);
    }, [])
  );

  function getDatesBetween(startDate: string, endDate: string): string[] {
    const dates: string[] = [];
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);

    while (
      currentDate.isBefore(lastDate) ||
      currentDate.isSame(lastDate, 'day')
    ) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    return dates;
  }

  return (
    <View>
      {timecard.is_approved && (
        <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            This week has been wrapped - all daily timecards & other payments
            are locked.
          </Text>
        </Flex>
      )}

      {!timecard.is_approved && moment().isSameOrAfter(timecard.start_date) && (
        <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            Select a work day to complete your daily timecard. Note: Hours show
            elapsed time, not time worked.
          </Text>
        </Flex>
      )}

      {!timecard.is_approved &&
        !moment().isSameOrAfter(timecard.start_date) && (
          <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
            <Text type="xs-medium" color="denim-50" className="px-6">
              This week is not active yet - timecards & payments are locked
              until the scheduled start date.
            </Text>
          </Flex>
        )}
      <Flex>
        {getDatesBetween(timecard.start_date, timecard.end_date).map((date) => {
          const scheduled = timecard.times.find((time) => time.date === date);

          const isMealCompleted = (meal: any, mealNumber: number) => {
            if (scheduled == null) return false;

            if (scheduled.required_meals_count == 0 || meal?.penalty) {
              return true;
            }

            if (scheduled.required_meals_count == 1 && mealNumber == 1) {
              return !isEmpty(meal?.start_time);
            }

            if (scheduled.required_meals_count == 2 && mealNumber == 2) {
              return !isEmpty(meal?.start_time);
            }

            return true;
          };

          const isFinished =
            scheduled &&
            !isEmpty(scheduled.checkin_time) &&
            !isEmpty(scheduled.checkout_time) &&
            isMealCompleted(scheduled.meal1, 1) &&
            isMealCompleted(scheduled.meal2, 2);

          const isActionRequired = () => {
            if (!scheduled) {
              return false;
            }

            const meal1Required = scheduled?.required_meals_count >= 1;
            const meal2Required = scheduled?.required_meals_count === 2;

            if (!scheduled?.checkin_time || !scheduled?.checkout_time) {
              return true;
            }

            if (meal1Required && !scheduled?.meal1) {
              return true;
            }

            if (meal2Required && !scheduled?.meal2) {
              return true;
            }

            return false;
          };

          const renderHoursBox = () => {
            if (isFinished && timecard.status === 'approved') {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-emerald-100/10 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="emerald-100">
                    {getElapsedHours(
                      scheduled?.checkin_time,
                      scheduled?.checkout_time
                    )}
                  </Text>
                  <Flex align="end">
                    <LockCircle
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('emerald-100')}
                    />
                  </Flex>
                </View>
              );
            }

            if (
              isFinished &&
              ((scheduled.checkin_by !== null &&
                scheduled.checkin_by === 'Self') ||
                (scheduled.checkout_by !== null &&
                  scheduled.checkout_by === 'Self'))
            ) {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-navy-light-100/10 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="navy-light-100">
                    {getElapsedHours(
                      scheduled?.checkin_time,
                      scheduled?.checkout_time
                    )}
                  </Text>
                  <Flex align="end">
                    <LockCircle
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('navy-light-100')}
                    />
                  </Flex>
                </View>
              );
            }

            if (
              isFinished &&
              ((scheduled.checkin_by !== null &&
                scheduled.checkin_by !== 'Self') ||
                (scheduled.checkout_by !== null &&
                  scheduled.checkout_by !== 'Self'))
            ) {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-grape-100/10 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="grape-100">
                    {getElapsedHours(
                      scheduled?.checkin_time,
                      scheduled?.checkout_time
                    )}
                  </Text>
                  <Flex align="end">
                    <LockCircle
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('grape-100')}
                    />
                  </Flex>
                </View>
              );
            }

            if (
              moment(scheduled?.date).isSameOrBefore(moment()) &&
              isActionRequired()
            ) {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-coral-100 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="white/100">
                    0.00
                  </Text>
                  <Text type="sm-medium" color="denim-10" className="ml-0.5">
                    hrs
                  </Text>
                </View>
              );
            }

            if (isFinished) {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-navy-light-100 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="white/100">
                    {/* {toTwoDecimal(scheduled?.work_duration!)} */}
                    {getElapsedHours(
                      scheduled?.checkin_time,
                      scheduled?.checkout_time
                    )}
                  </Text>
                  <Text type="sm-medium" color="denim-10">
                    hrs
                  </Text>
                </View>
              );
            }

            if (scheduled) {
              return (
                <View className="flex flex-row w-[85px] justify-between items-center bg-gray-200 px-3 py-3.5 rounded-xl">
                  <Text type="sm-medium" color="denim-50">
                    --.--
                  </Text>
                  <Text type="sm-medium" color="denim-50">
                    hrs
                  </Text>
                </View>
              );
            }

            return null;
          };

          const renderDailyText = () => {
            if (
              timecard.incomplete_days_count === 0 &&
              isFinished &&
              timecard.status === 'approved'
            ) {
              return (
                <Text type="base-medium" color="emerald-100">
                  Approved
                </Text>
              );
            }

            if (
              isFinished &&
              scheduled.is_locked &&
              ((scheduled.checkin_by !== null &&
                scheduled.checkin_by === 'Self') ||
                (scheduled.checkout_by !== null &&
                  scheduled.checkout_by === 'Self'))
            ) {
              return (
                <Text type="base-medium" color="navy-light-100">
                  Admin Confirmed
                </Text>
              );
            }

            if (
              isFinished &&
              ((scheduled.checkin_by !== null &&
                scheduled.checkin_by !== 'Self') ||
                (scheduled.checkout_by !== null &&
                  scheduled.checkout_by !== 'Self'))
            ) {
              return (
                <Text type="base-medium" color="grape-100">
                  Admin Updated
                </Text>
              );
            }

            if (isFinished) {
              return (
                <Text type="base-medium" color="navy-light-100">
                  Complete
                </Text>
              );
            }

            if (
              moment(scheduled?.date).isSameOrBefore(moment()) &&
              isActionRequired()
            ) {
              return (
                <Text type="base-medium" color="coral-100">
                  Incomplete
                </Text>
              );
            }

            if (scheduled) {
              return <Text type="base-medium">Scheduled</Text>;
            }

            return (
              <Text type="base-medium" color="denim-50">
                -
              </Text>
            );
          };

          return (
            <TouchableOpacity
              disabled={!scheduled}
              key={date}
              className={`flex flex-row items-center h-14 justify-between py-1.5 border-b px-6 border-gray-300 ${
                moment(scheduled?.date).isSameOrBefore(moment()) &&
                isActionRequired()
                  ? 'bg-coral-100/10'
                  : 'white/100'
              }`}
              onPress={() => onDayChose(scheduled!)}
            >
              <FlexRow>
                <Flex className="w-4 flex text-center justify-center">
                  <Text
                    type="base-medium"
                    className="text-center"
                    color={scheduled ? 'black' : 'denim-40'}
                  >
                    {moment(date).format('ddd').slice(0, -2)}
                  </Text>
                </Flex>
                <Text
                  type="base-medium"
                  className="ml-6 mr-12"
                  color={scheduled ? 'black' : 'denim-40'}
                >
                  {moment(date).format('MM/DD')}
                </Text>
                {renderDailyText()}
              </FlexRow>
              {renderHoursBox()}
            </TouchableOpacity>
          );
        })}
      </Flex>
      {showDonePopup &&
        timecard.approved_at == null &&
        timecard.incomplete_days_count == 0 && (
          <FloatingBottom>
            <Text type="sm-semibold" color="coral-100">
              Timecards Ready for Final Review
            </Text>

            <Text type="sm-medium" color="denim-50" className="mt-5">
              To ensure timely payment, please go to the Review Tab to approve
              your timecards as soon as possible.
            </Text>

            <Button
              useFullColor={true}
              value="Go to Review"
              onPress={onReview}
              color="coral-100"
              className="mt-5 mb-2"
            />

            <SecondaryButton
              value="Stay Here"
              onPress={() => setShowDonePopup(false)}
            />
          </FloatingBottom>
        )}
    </View>
  );
};
