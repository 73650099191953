import { Image } from 'react-native';
import FlexRow from '@components/Views/FlexRow';

export default function MainLogo({ ...props }) {
  return (
    <FlexRow
      className="flex-1"
      verticalAlign="center"
      align="center"
      {...props}
    >
      <Image
        source={require('@assets/images/logo.png')}
        resizeMode="contain"
        className="w-2/3 h-12"
      />
    </FlexRow>
  );
}
