import React, { useEffect, useState } from 'react';
import Flex from '@components/Views/Flex';
import Header from '@components/Views/Header';
import ContentTabs from '@components/General/ContentTabs';
import ProductionsHistory from '@pages/SignedIn/History/components/ProductionsHistory';
import OffersHistory from '@pages/SignedIn/History/components/OffersHistory';
import TimecardsHistory from '@pages/SignedIn/History/components/TimecardsHistory';
import PaystubsHistory from '@pages/SignedIn/History/components/PaystubsHistory';
import { AuthStackParamList } from '@src/routes';
import { StackScreenProps } from '@react-navigation/stack';

type HistoryProps = StackScreenProps<AuthStackParamList, 'History'>;
export default function History({ navigation, route }: HistoryProps) {
  const [selectedTab, setSelectedTab] = useState<string>(route.params.tab);

  useEffect(() => {
    navigation.setParams({ ...route.params, tab: selectedTab });
  }, [selectedTab]);

  return (
    <Flex className="flex-1 bg-white">
      <Header title="Your History" background="bg-denim-light-100" />
      <ContentTabs
        values={['Offers', 'Productions', 'Timecards', 'Paystubs']}
        defaultSelected={selectedTab}
        onSelected={setSelectedTab}
        background="bg-denim-light-100"
        btnBackground="bg-navy-light-100"
      />

      {selectedTab === 'offers' && <OffersHistory />}
      {selectedTab === 'productions' && <ProductionsHistory />}
      {selectedTab === 'timecards' && <TimecardsHistory />}
      {selectedTab === 'paystubs' && <PaystubsHistory />}
    </Flex>
  );
}
