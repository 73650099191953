import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RBSheet from 'react-native-raw-bottom-sheet';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Button from '@components/Buttons/Button';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import Header from '@components/Views/Header';
import FloatingBottom from '@components/Views/FloatingBottom';

import productionApi from '@api/productionApi';
import { NonAuthStackParamList } from '@src/routes';
import { formatDateDefault, msToHMS } from '@src/helpers';
import { Details } from '@src/pages/SignedIn/Production/components/OverviewTab/Details';
import { GuarantedHours } from '@src/pages/SignedIn/Production/components/OverviewTab/GuarantedHours';
import { ProductionDetails } from '@src/components/General/ProductionDetails';
import { Locations } from '@src/components/General/Locations';
import { CrewPolicies } from '@src/components/General/CrewPolicies';
import { translatedGuaranteedHours } from "@components/General/Rates";
import { DeclineModal } from './components/DeclineModal';
import { DetailsModal } from './components/DetailsModal';

// Types
type OfferSummaryProps = StackScreenProps<NonAuthStackParamList, 'OfferSummary'>;

interface Offer {
  role: string;
  company_name: string;
  status: string;
  expiration: string | null;
  production_id: string;
  production_name: string;
  production_type: string;
  production_synopsis: string;
  cities: Array<{
    last_shooting_day: string;
    first_shooting_day: string;
    city: string;
  }>;
  policies: {
    travel?: string;
    expense?: string;
    bonus?: string;
  };
  employment: string;
  require_i9: boolean;
  rate: any;
  related: any;
  name: string;
  first_shooting_day: string;
  last_shooting_day: string;
  total_shooting_days: number;
  accepted_at: string;
  daily_hours: number;
  daily_rate: number;
  feedback: string | null;
}

interface ModalState {
  title: string;
  content: string;
}

export default function OfferSummary({ navigation, route }: OfferSummaryProps) {
  // State
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [timeLeft, setTimeLeft] = useState('00:00:00');
  const [modalState, setModalState] = useState<ModalState>({ title: '', content: '' });

  // Refs
  const refDeclineOffer = useRef<RBSheet>(null);
  const refViewDetailsPopup = useRef<RBSheet>(null);

  // Effects
  useEffect(() => {
    getOffer();
  }, [navigation, route]);

  useEffect(() => {
    if (!offer?.expiration) {
      setTimeLeft('-');
      return;
    }

    const expirationTime = moment(offer.expiration);
    if (moment().isAfter(expirationTime)) {
      setTimeLeft('00:00:00');
      return;
    }

    const interval = setInterval(() => {
      const diff = expirationTime.diff(moment());
      if (diff <= 0) {
        setTimeLeft('00:00:00');
        clearInterval(interval);
        return;
      }
      setTimeLeft(msToHMS(diff));
    }, 1000);

    return () => clearInterval(interval);
  }, [offer?.expiration]);

  // Handlers
  const getOffer = async () => {
    setLoading(true);
    try {
      const response = await productionApi.getPublicOffer(route.params.id);

      if (response.data.has_login && response.data.status !== 'expired' && navigation.getState().routeNames.includes('Login')) {
        navigation.navigate('Login');
        return;
      }

      setOffer(response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async (type: number) => {
    refDeclineOffer.current?.close();
    setLoading(true);
    try {
      await productionApi.rejectPublicOffer(route.params.id, type === 1);
      await getOffer();
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    const response = await productionApi.acceptPublicOffer(route.params.id, {
      loan_out: false,
    });

    const signupData = response.data.signup;
    await AsyncStorage.setItem('signupData', JSON.stringify(signupData));
    await updateLocalStorage();

    navigation.navigate('HowItWorks');
  };

  const updateLocalStorage = async () => {
    await AsyncStorage.setItem('signupOfferId', route.params.id);
    await AsyncStorage.setItem('signupProductionId', offer?.production_id || '');
    await AsyncStorage.setItem('signupProductionName', offer?.production_name || '');
    await AsyncStorage.setItem('signupProductionEmployment', offer?.employment || '');
    await AsyncStorage.setItem('signupProductionHasI9', String(offer?.require_i9 || false));
    await AsyncStorage.setItem('signupProductionLoanOut', 'false');
  }

  const showDetails = (title: string, content: string) => {
    setModalState({ title, content });
    refViewDetailsPopup.current?.open();
  };

  if (!offer) return null;

  return (
    <Flex className="bg-white flex flex-1" verticalAlign="between">
      <Header title="Offer Details" showBack={false} />
      <ScrollView>
        <Flex className="gap-y-1 py-4 mx-6">
          <FlexRow verticalAlign="center" align="between">
            <Text type="sm-semibold" color="navy-100">
              {offer.role}
            </Text>
            {offer.status !== 'rejected' && offer.status !== 'expired' && (
              <Text type="xs-semibold" color="coral-100">
                {timeLeft === '-' ? 'Expires In: N/A' : `Expires in ${timeLeft}`}
              </Text>
            )}
            {offer.status === 'rejected' && (
              <Text type="xs-semibold" color="coral-100">Declined</Text>
            )}
            {offer.status === 'expired' && (
              <Text type="xs-semibold" color="coral-100">Expired</Text>
            )}
          </FlexRow>

          <Text type="xs-medium" color="denim-50" className="mt-1.5">
            Offered By: {offer.company_name}
          </Text>
          <View className="border-b border-navy-light-100/10 pt-3 -mx-7 mb-3" />
        </Flex>

        <Details
          production={{
            attributes: {
              ...offer,
              guaranteed_hours: offer.rate?.guaranteed_hours || 0,
              rating: 0,
              synopsis: offer.production_synopsis,
              type: offer.production_type,
              name: offer.production_name,
              first_shooting_day: offer.first_shooting_day,
              last_shooting_day: offer.last_shooting_day,
              total_shooting_days: offer.total_shooting_days,
              status: offer.status,
              accepted_at: offer.accepted_at,
              daily_hours: offer.daily_hours,
              daily_rate: offer.daily_rate,
              feedback: offer.feedback,
              offer_id: route.params.id,
              contact: {
                name: '',
                title: '',
                email: '',
                phone_number: '',
              },
            },
            id: offer.production_id,
            related: offer.related,
          }}
        />
        <GuarantedHours hoursAmount={translatedGuaranteedHours(offer.rate)}/>

        <Flex className="mt-10">
          <ProductionDetails
            title={offer.production_name}
            type={offer.production_type}
            onOpenSynopsis={() => showDetails('Production Synopsis', offer.production_synopsis)}
          />
        </Flex>

        <Flex className="mt-10 mb-10">
          <Locations
            cities={offer.cities.map(({ last_shooting_day, first_shooting_day, city }) => ({
              location: `${city} (${moment(last_shooting_day).diff(moment(first_shooting_day), 'days')} Days)`,
              date: `${formatDateDefault(first_shooting_day)} - ${formatDateDefault(last_shooting_day)}`,
            }))}
          />
        </Flex>

        {(offer.policies.travel || offer.policies.expense || offer.policies.bonus) && (
          <Flex>
            <CrewPolicies
              policies={[
                { title: 'Travel Compensation', policy: offer.policies.travel || '' },
                { title: 'Expense', policy: offer.policies.expense || '' },
                { title: 'Reimbursement', policy: offer.policies.bonus || '' },
              ].filter(({ policy }) => policy)}
              onOpenPolicy={(policy) => {
                const content = offer.policies[policy.toLowerCase().replace(' ', '_') as keyof typeof offer.policies];
                if (content) showDetails(policy, content);
              }}
            />
          </Flex>
        )}
      </ScrollView>

      {(offer.status === 'active' || offer.status === null) && (
        <FloatingBottom>
          <Button
            useFullColor={true}
            value="Accept Offer & Proceed"
            onPress={handleAccept}
          />
          <SecondaryButton
            value="Decline Offer"
            className="mt-2"
            onPress={() => refDeclineOffer.current?.open()}
          />
        </FloatingBottom>
      )}

      {offer.status === 'accepted' && (
        <FloatingBottom>
          <Button
            useFullColor={true}
            value="Continue"
            onPress={handleAccept}
          />
        </FloatingBottom>
      )}

      <DeclineModal
        ref={refDeclineOffer}
        onDecline={handleDecline}
        loading={loading}
      />

      <DetailsModal
        ref={refViewDetailsPopup}
        title={modalState.title}
        content={modalState.content}
      />
    </Flex>
  );
}
