import React, { useEffect, useState } from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import TextInput from '@components/Forms/TextInput';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import FlexRow from '@components/Views/FlexRow';
import { userLoginType } from '@interfaces/UserInterface';
import userApi from '@api/userApi';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setGlobal } from 'reactn';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import FloatingBottom from '@components/Views/FloatingBottom';
import { showMessage } from 'react-native-flash-message';

export default function FilmupAccount() {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);

    try {
      let response: userLoginType = await userApi.getAccount();
      setEmail(response.data.attributes.email);
    } catch (e) {}

    setLoading(false);
  };

  const updateDetails = async () => {
    setLoading(true);

    try {
      let response: userLoginType = await userApi.putAccount({
        email: email,
        password: password,
        confirm_password: confirmPassword,
      });

      setPassword('');
      setConfirmPassword('');

      showMessage({
        message: 'Success!',
        description: 'Account details updated successfully.',
        type: 'success',
      });
    } catch (e) {}

    setLoading(false);
  };

  const logout = async () => {
    try {
      await AsyncStorage.removeItem('access_token');
      await AsyncStorage.removeItem('user');
      await setGlobal({
        user: null,
        access_token: null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex className="flex-1" verticalAlign="content-between">
      <Flex className="flex-1 bg-white" verticalAlign="between">
        <Flex className="flex-1 px-5 gap-y-2 pb-5 pt-5">
          <TextInput label="Email" onChange={setEmail} value={email} />
          <TextInput
            label="Password"
            password={true}
            onChange={setPassword}
            value={password}
          />
          <TextInput
            label="Confirm Password"
            password={true}
            onChange={setConfirmPassword}
            value={confirmPassword}
          />
        </Flex>

        <Flex>
          <FlexRow verticalAlign="center" align="center" className="pt-2">
            <Text type="xs-bold" className="ml-3">
              FilmUp's
            </Text>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL('https://dashboard.filmup.co/service-terms')
              }
            >
              <Text type="xs-bold" color="navy-light-100">
                {' '}
                Terms of Use{' '}
              </Text>
            </TouchableOpacity>
            <Text type="xs-bold">and</Text>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL('https://dashboard.filmup.co/privacy-policy')
              }
            >
              <Text type="xs-bold" color="navy-light-100">
                {' '}
                Privacy Policy{' '}
              </Text>
            </TouchableOpacity>
          </FlexRow>
          <FloatingBottom>
            <Button
              useFullColor={true}
              value="Save"
              className="flex-grow"
              onPress={() => updateDetails()}
              disabled={password == '' || confirmPassword == '' || email == ''}
            />

            <SecondaryButton
              onPress={() => logout()}
              value="Logout"
              className="my-2"
            />

            <Flex align="center" className="space-y-2">
              <Text type="2xs-normal">
                App Version: {Constants.expoConfig.version}
              </Text>
              <TouchableOpacity
                onPress={() => Linking.openURL('mailto:support@filmup.co')}
              >
                <Text type="2xs-normal">Contact support</Text>
              </TouchableOpacity>
            </Flex>
          </FloatingBottom>
        </Flex>
      </Flex>
    </Flex>
  );
}
