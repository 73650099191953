import { useFocusEffect, useNavigation } from '@react-navigation/native';
import productionApi from '@src/api/productionApi';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import { ActivityIndicator, View } from 'react-native';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { navigationProps } from '@src/interfaces/RoutesInterface';

type Paystub = {
  attributes: {
    end_date: string;
    start_date: string;
  };
  id: string;
  related: {
    production: {
      data: {
        id: string;
      };
    };
  };
};

export const PaystubList = () => {
  const navigation = useNavigation<navigationProps>();

  const [paystubs, setPaystubs] = useState<Paystub[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useFocusEffect(
    useCallback(() => {
      getPaystubs();
    }, [])
  );

  const getPaystubs = async () => {
    setLoading(true);

    let response = await productionApi.getPaystubList();
    setPaystubs(response.data);

    setLoading(false);
  };

  if (!loading && !paystubs.length) {
    return (
      <ScrollView>
        <Flex className="flex-1 mt-5" align="center">
          <Text type="sm-semibold" color="denim-50">
            No paystubs to display
          </Text>
        </Flex>
      </ScrollView>
    );
  }

  return (
    <ScrollView>
      <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Select any Pay Period to view your Paystub details from that week.
        </Text>
      </Flex>

      <Flex className="w-full">
        {loading && <ActivityIndicator className="mt-10" />}
        {paystubs.map((paystub, index) => (
          <TouchableOpacity
            key={paystub.attributes.end_date}
            onPress={() =>
              navigation.navigate('ProductionPaystub', {
                productionId: paystub.related.production.data.id,
                id: paystub.id,
              })
            }
          >
            <View className="flex flex-row border-b items-center py-5 px-6 border-gray-300 justify-between">
              <Flex>
                <Text type="sm-semibold" className="w-36">
                  {moment(new Date(paystub.attributes.start_date)).format(
                    'MMM DD'
                  )}{' '}
                  -{' '}
                  {moment(new Date(paystub.attributes.end_date)).format(
                    'MMM DD'
                  )}
                </Text>
                <Text type="xs-medium" color="denim-50">
                  Week {index + 1} of {paystubs.length}
                </Text>
              </Flex>
              <Text type="sm-medium" color="denim-50">
                Not Ready
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </Flex>
    </ScrollView>
  );
};
