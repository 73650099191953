import React, { Fragment } from 'react';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import {
  translatedKitFee,
  translatedRateAmount,
} from '@components/General/Rates';
import moment from 'moment';
import { productionType } from '@src/interfaces/ProductionInterface';

type DetailsProps = {
  production: productionType;
};

export const Details = ({ production }: DetailsProps) => {
  const details = [
    {
      title: 'Working Days',
      value: `${production.attributes.total_shooting_days} Days`,
    },

    {
      title: 'Working Dates',
      value: `${moment(production.attributes.first_shooting_day).format(
        'MMM DD'
      )} - ${moment(production.attributes.last_shooting_day).format(
        'MMM DD, YYYY'
      )}`,
    },
    {
      title: 'Hired As',
      value: production.attributes.employment?.toUpperCase(),
    },
    {
      title: 'Pay Rate',
      value: translatedRateAmount(production.attributes.rate),
    },
    {
      title: 'Kit Rental',
      value: translatedKitFee(production.attributes.rate),
    },
  ];

  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5">
        Role Details
      </Text>

      <Flex className="bg-slate-800/5 py-4">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Information about your role on this production.
        </Text>
      </Flex>

      <Flex className="px-6">
        {details.map((detail) => (
          <FlexRow
            key={detail.title}
            verticalAlign="center"
            align="between"
            className="h-11 border-b-0.5 border-denim-20"
          >
            <Text type="sm-medium" color="denim-50">
              {detail.title}
            </Text>
            <Text type="sm-medium" color="slate-800">
              {detail.value}
            </Text>
          </FlexRow>
        ))}
      </Flex>
    </Fragment>
  );
};
