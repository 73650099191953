import React, { useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';

import FlexRow from '@components/Views/FlexRow';
import Flex from '@components/Views/Flex';
import Text from '@components/General/Text';

type TabsProps = {
  values: string[];
  defaultSelected: string | null;
  align?: string;
  onSelected: (value: string) => void;
  ribbles?: number[];
  className?: string;
};
export default function SubContentTabs({
  values,
  align = 'between',
  onSelected,
  defaultSelected,
  ribbles,
  ...props
}: TabsProps) {
  const [selected, setSelected] = React.useState(0);
  const updateSelected = (value: string, index: number) => {
    setSelected(index);
    onSelected(value.toLowerCase());
  };

  useEffect(() => {
    if (defaultSelected != null) {
      const indexDefaultSelected = values.findIndex(
        (value) => value.toLowerCase() === defaultSelected.toLowerCase()
      );
      setSelected(indexDefaultSelected);
    }
  }, [defaultSelected]);

  return (
    <FlexRow
      verticalAlign="center"
      align={align}
      className={`w-full bg-white h-14 text-white/40 border-b border-white/20`}
      {...props}
    >
      {values.map((value, index) => (
        <TouchableOpacity
          className={`h-14 w-1/3 px-2 border-b ${
            selected == index ? 'border-navy-light-100' : 'border-transparent'
          }`}
          onPress={() => updateSelected(value, index)}
          key={index}
        >
          <FlexRow verticalAlign="center" align="center" className="flex-1">
            <Text
              type="xs-medium"
              color={selected == index ? 'navy-light-100' : 'denim-50'}
              className="text-center truncate"
            >
              {value}
            </Text>
            {ribbles?.[index] && (
              <View className="-mt-5">
                <Text
                  type="xs-semibold"
                  className={`${
                    selected == index ? 'bg-navy-light-100' : 'bg-coral-100'
                  }
                                ${
                                  ribbles && ribbles[index]
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                }
                                text-white/100 w-5 justify-center flex align-middle items-center text-center  rounded-full h-5`}
                >
                  {ribbles?.[index] || ''}
                </Text>
              </View>
            )}
          </FlexRow>
        </TouchableOpacity>
      ))}
    </FlexRow>
  );
}
