import React from 'react';
import { transformTailwindToHex } from '@src/helpers';
import Flex from '@components/Views/Flex';
import Text from '@components/General/Text';

import CheckCircle from '@assets/icons/check-circle.svg';
import AlertCircle from '@assets/icons/alert-circle.svg';
import CurrencyInput from 'react-native-currency-input';

type FormField = {
  label: string;
  value: number | null;
  required?: boolean;
  onChange: (value: number | null) => void;
  paddingLeft?: string;
};

export default function CurrencyInputStyled({
  required = true,
  ...props
}: FormField) {
  return (
    <Flex className="w-full">
      {props.value != 0 && props.value != null && (
        <Flex
          style={{
            paddingHorizontal: 5,
            marginBottom: -6,
            marginLeft: 12,
            zIndex: 1,
            backgroundColor: '#FFF',
            marginTop: -6,
            width: 'fit-content',
          }}
        >
          <Text style={{ fontSize: 10, width: 'fit-content' }} color="denim-50">
            {props.label}
          </Text>
        </Flex>
      )}

      <CurrencyInput
        value={props.value}
        onChangeValue={props.onChange}
        delimiter=","
        separator="."
        precision={2}
        minValue={0}
        placeholder={props.label}
        className={`w-full p-3 ${props.paddingLeft ? props.paddingLeft : ''} h-12 rounded-xl text-sm mb-4 border border-denim-20 placeholder-denim-50`}
      />

      {props.value != 0 && props.value != null && (
        <Flex align="end" className="absolute top-4 right-4">
          <CheckCircle
            width={16}
            height={16}
            fill={transformTailwindToHex('navy-light-100')}
          />
        </Flex>
      )}

      {(props.value == 0 || props.value == null) && required && (
        <Flex align="end" className="absolute top-4 right-4">
          <AlertCircle
            width={16}
            height={16}
            fill={transformTailwindToHex('coral-100')}
          />
        </Flex>
      )}
    </Flex>
  );
}
