import React, { useState } from 'react';
import { Linking, ScrollView, TouchableOpacity } from 'react-native';

import productionApi from '@api/productionApi';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { formatDateDefault, formatDateDefaultYear } from '@src/helpers';
import { RefreshControl } from 'react-native-web-refresh-control';
import NoData from '@components/General/NoData';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SubContentTabs from '@components/General/SubContentTabs';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { navigationProps } from '@interfaces/RoutesInterface';
import PaystubsHistoryByYear from '@pages/SignedIn/History/components/PaystubsHistoryByYear';

export default function PaystubsHistory() {
  const navigation = useNavigation<navigationProps>();

  const [paystubs, setPaystubs] = useState<any>([]); // @TODO: Fix this type
  const [taxForms, setTaxForms] = useState<any>([]); // @TODO: Fix this type
  const [selectedTab, setSelectedTab] = useState<string | null>('by week');
  const [loading, setLoading] = useState<boolean>(true);
  const insets = useSafeAreaInsets();

  useFocusEffect(
    React.useCallback(() => {
      getPaystubs();
      getTaxForms();
    }, [])
  );

  const getPaystubs = async () => {
    setLoading(true);

    let response = await productionApi.getPaystubList();
    setPaystubs(response.data);

    setLoading(false);
  };

  const getTaxForms = async () => {
    setLoading(true);

    let response = await productionApi.getTaxForms();
    setTaxForms(response.data);

    setLoading(false);
  };

  return (
    <Flex className="flex-1">
      <SubContentTabs
        values={['By Week', 'Year-to-Date', 'Tax Forms']}
        align="start"
        defaultSelected={selectedTab}
        onSelected={(value) => setSelectedTab(value)}
      />
      {selectedTab === 'by week' && (
        <Flex className="flex-1 px-5">
          <ScrollView
            className="flex-1"
            style={{
              paddingBottom: insets.bottom == 0 ? 20 : insets.bottom,
            }}
            refreshControl={
              <RefreshControl refreshing={loading} onRefresh={getPaystubs} />
            }
          >
            {paystubs.length == 0 && !loading && (
              <NoData text="You don't have any paystubs yet." />
            )}

            {paystubs.length > 0 &&
              !loading &&
              paystubs.map((paystub, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      navigation.navigate('Production', {
                        productionId: paystub.related.production.data.id,
                        tab: 'your-role',
                        subtab: 'paystubs',
                      });
                    }}
                  >
                    <Flex className="border-b border-gray-200 py-4">
                      <FlexRow
                        verticalAlign="center"
                        align="between"
                        className="mb-2"
                      >
                        <Text type="sm-medium">
                          {paystub.related.production.data.attributes.name}
                        </Text>
                        <Text type="xs-medium" color="emerald-100">
                          ${paystub.attributes.net_pay}
                        </Text>
                      </FlexRow>
                      <FlexRow
                        verticalAlign="center"
                        align="between"
                        className="mb-1"
                      >
                        <Text type="xs-medium" color="denim-50">
                          {formatDateDefault(paystub.attributes.start_date)} -{' '}
                          {formatDateDefaultYear(paystub.attributes.end_date)}
                        </Text>
                        <Text type="xs-medium" color="denim-50">
                          {paystub.related.role.data.attributes.name}
                        </Text>
                      </FlexRow>
                    </Flex>
                  </TouchableOpacity>
                );
              })}
          </ScrollView>
        </Flex>
      )}

      {selectedTab === 'year-to-date' && <PaystubsHistoryByYear />}

      {selectedTab === 'tax forms' && (
        <Flex className="flex-1 px-5">
          <ScrollView
            className="flex-1"
            style={{
              paddingBottom: insets.bottom == 0 ? 20 : insets.bottom,
            }}
            refreshControl={
              <RefreshControl refreshing={loading} onRefresh={getTaxForms} />
            }
          >
            {taxForms.length == 0 && !loading && (
              <NoData text="You don't have any data to display." />
            )}

            {taxForms.length > 0 &&
              !loading &&
              taxForms.map((taxForm, index) => {
                return (
                  <FlexRow
                    className="py-4 border-b border-denim-10"
                    verticalAlign="center"
                  >
                    <Flex className="flex-grow">
                      <FlexRow align="between" verticalAlign="center">
                        <Text type="sm-medium">{taxForm.attributes.title}</Text>
                      </FlexRow>
                      <Text type="xs-medium" color="denim-50">
                        {formatDateDefaultYear(
                          taxForm.attributes.expiration_date
                        )}
                      </Text>
                    </Flex>

                    {taxForm.attributes.url !== null && (
                      <Flex verticalAlign="start" align="end">
                        <TouchableOpacity
                          onPress={() =>
                            Linking.openURL(taxForm.attributes.url)
                          }
                        >
                          <Text type="xs-medium" color="navy-light-100">
                            View
                          </Text>
                        </TouchableOpacity>
                      </Flex>
                    )}
                  </FlexRow>
                );
              })}
          </ScrollView>
        </Flex>
      )}
    </Flex>
  );
}
