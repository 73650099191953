import React, { useState } from 'react';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Flex from '@components/Views/Flex';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthStackParamList } from '@src/routes';
import Text from '@components/General/Text';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import FlexRow from '@components/Views/FlexRow';
import ArrowRight from '@assets/icons/arrow-right.svg';
import Film from '@assets/icons/film.svg';
import Invoice from '@assets/icons/invoice.svg';
import Clock from '@assets/icons/clock.svg';
import Offers from '@assets/icons/offers.svg';
import {
  formatDateDefault,
  formatDateDefaultYear,
  transformTailwindToHex,
} from '@src/helpers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import { nextShootType } from '@interfaces/FeedInterface';
import productionApi from '@api/productionApi';

import * as Linking from 'expo-linking';

type HomeProps = StackScreenProps<AuthStackParamList, 'Home'>;
export default function Home({ navigation }: HomeProps) {
  const insets = useSafeAreaInsets();
  const [activeProduction, setActiveProduction] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useFocusEffect(
    React.useCallback(() => {
      checkIfNeedRedirect();
      getActiveProduction();
      checkUrlLink();
    }, [])
  );

  const getActiveProduction = async () => {
    setLoading(true);

    let response: nextShootType = await productionApi.getFeed();
    // @ts-ignore
    setActiveProduction(response.data[0] ?? null);

    setLoading(false);
  };

  const checkIfNeedRedirect = async () => {
    try {
      let redirectTo = await AsyncStorage.getItem('redirectTo');
      if (redirectTo !== null) {
        await AsyncStorage.removeItem('redirectTo');

        let redirectToJSON = JSON.parse(redirectTo);
        navigation.navigate(redirectToJSON.page, redirectToJSON.params);
      }

      let initialOnboarding = await AsyncStorage.getItem('initialOnboarding');
      if (initialOnboarding !== null) {
        let redirectToJSON = JSON.parse(initialOnboarding);
        navigation.navigate(redirectToJSON.page, redirectToJSON.params);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkUrlLink = async () => {
    const initialUrl = await Linking.getInitialURL();

    if (!initialUrl) {
      return;
    }

    const { path } = Linking.parse(initialUrl);

    if (path?.includes('offer-summary')) {
      let offerId = path.split('/')[1];
      navigation.navigate('Offer', { offerId: offerId });
    }
  };

  return (
    <Flex className="flex-1 bg-white" style={{ paddingTop: insets.top }}>
      {loading && (
        <Flex verticalAlign="center" align="center" className="flex-1">
          <ActivityIndicator className="mt-5" />
        </Flex>
      )}
      {!loading && (
        <Flex className="flex-1">
          <Flex className="px-5 h-3/5 flex-1">
            <Flex className="h-12" verticalAlign="center" align="center">
              <Text
                type="xl-semibold"
                color="denim-100"
                className="text-center"
              >
                Home
              </Text>
            </Flex>
            <Flex className="flex-1 pt-5" verticalAlign="center">
              <Flex className="gap-y-2">
                <Text type="xs-medium" color="navy-50">
                  Your{' '}
                  {activeProduction?.status == 'active' ? 'Active' : 'Latest'}{' '}
                  Production
                </Text>

                {activeProduction != null && (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('Production', {
                        productionId: activeProduction.id,
                        tab: 'work-schedule',
                      })
                    }
                  >
                    <Flex className="rounded-xl bg-navy-light-100 py-5 px-4">
                      <FlexRow verticalAlign="center" align="between">
                        <Text type="sm-medium" color="white/100">
                          {activeProduction.production}
                        </Text>
                        <ArrowRight width={12} height={12} fill={'#fff'} />
                      </FlexRow>

                      <Text
                        type="xs-medium"
                        color="white/100"
                        className="mt-1 mb-5"
                      >
                        {activeProduction.company}
                      </Text>

                      <FlexRow
                        verticalAlign="center"
                        align="start"
                        className="gap-x-2"
                      >
                        <Text type="xs-medium" color="white/100">
                          {activeProduction.total_shooting_days} Days
                        </Text>
                        <Text type="xs-medium" color="white/100">
                          •
                        </Text>
                        <Text type="xs-medium" color="white/100">
                          {formatDateDefault(
                            activeProduction.first_shooting_day
                          )}{' '}
                          -{' '}
                          {formatDateDefaultYear(
                            activeProduction.last_shooting_day
                          )}
                        </Text>
                      </FlexRow>
                    </Flex>
                  </TouchableOpacity>
                )}

                {activeProduction == null && (
                  <Flex className="rounded-xl bg-navy-light-100/20 py-10 px-4">
                    <Text type="sm-medium" color="white/100">
                      No Productions Active at the moment
                    </Text>
                  </Flex>
                )}

                <TouchableOpacity
                  disabled={activeProduction == null}
                  className={activeProduction == null ? 'opacity-50' : ''}
                  onPress={() =>
                    navigation.navigate('Production', {
                      productionId: activeProduction?.id,
                      tab: 'work-schedule',
                    })
                  }
                >
                  <FlexRow
                    className="border border-denim-20 rounded-xl h-12 px-4"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="sm-medium" color="denim-100">
                      Timecard
                    </Text>
                    <ArrowRight
                      width={12}
                      height={12}
                      fill={transformTailwindToHex('denim-100')}
                    />
                  </FlexRow>
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={activeProduction == null}
                  className={activeProduction == null ? 'opacity-50' : ''}
                  onPress={() =>
                    navigation.navigate('Production', {
                      productionId: activeProduction?.id,
                      tab: 'work-schedule',
                    })
                  }
                >
                  <FlexRow
                    className="border border-denim-20 rounded-xl h-12 px-4"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="sm-medium" color="denim-100">
                      Work Schedule
                    </Text>
                    <ArrowRight
                      width={12}
                      height={12}
                      fill={transformTailwindToHex('denim-100')}
                    />
                  </FlexRow>
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={activeProduction == null}
                  className={activeProduction == null ? 'opacity-50' : ''}
                  onPress={() =>
                    navigation.navigate('Production', {
                      productionId: activeProduction?.id,
                      tab: 'your-role',
                      subtab: 'paystubs',
                    })
                  }
                >
                  <FlexRow
                    className="border border-denim-20 rounded-xl h-12 px-4"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="sm-medium" color="denim-100">
                      Paystubs
                    </Text>
                    <ArrowRight
                      width={12}
                      height={12}
                      fill={transformTailwindToHex('denim-100')}
                    />
                  </FlexRow>
                </TouchableOpacity>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            className="bg-navy-light-100/5 border-t border-denim-20 px-5 h-2/5"
            verticalAlign="between"
          >
            <Flex className="flex-1" verticalAlign="center">
              <Text type="xs-medium" color="navy-50" className="mb-2">
                Your History
              </Text>

              <FlexRow className="gap-x-2">
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('History', {
                      tab: 'offers',
                    })
                  }
                  className="flex-1"
                >
                  <Flex
                    className="border border-denim-20 rounded-xl py-8"
                    verticalAlign="center"
                    align="center"
                  >
                    <Offers
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('denim-100')}
                    />
                    <Text type="sm-medium" color="denim-100" className="mt-2">
                      Offers
                    </Text>
                  </Flex>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('History', {
                      tab: 'productions',
                    })
                  }
                  className="flex-1"
                >
                  <Flex
                    className="border border-denim-20 rounded-xl py-8"
                    verticalAlign="center"
                    align="center"
                  >
                    <Film
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('denim-100')}
                    />
                    <Text type="sm-medium" color="denim-100" className="mt-2">
                      Productions
                    </Text>
                  </Flex>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('History', {
                      tab: 'timecards',
                    })
                  }
                  className="flex-1"
                >
                  <Flex
                    className="border border-denim-20 rounded-xl py-8"
                    verticalAlign="center"
                    align="center"
                  >
                    <Clock
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('denim-100')}
                    />
                    <Text type="sm-medium" color="denim-100" className="mt-2">
                      Timecards
                    </Text>
                  </Flex>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('History', {
                      tab: 'paystubs',
                    })
                  }
                  className="flex-1"
                >
                  <Flex
                    className="border border-denim-20 rounded-xl py-8"
                    verticalAlign="center"
                    align="center"
                  >
                    <Invoice
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('denim-100')}
                    />
                    <Text type="sm-medium" color="denim-100" className="mt-2">
                      Paystubs
                    </Text>
                  </Flex>
                </TouchableOpacity>
              </FlexRow>
            </Flex>

            <Flex style={{ paddingBottom: insets.bottom + 20 }}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('Settings', {
                    tab: 'filmup-account',
                  })
                }
              >
                <FlexRow
                  className="border border-denim-20 rounded-xl h-12 px-4"
                  verticalAlign="center"
                  align="between"
                >
                  <Text type="sm-medium" color="denim-100">
                    Settings
                  </Text>
                  <ArrowRight
                    width={12}
                    height={12}
                    fill={transformTailwindToHex('denim-100')}
                  />
                </FlexRow>
              </TouchableOpacity>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
