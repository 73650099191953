import {
  Dimensions,
  Platform,
  TextInput as RNTextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import Flex from '@components/Views/Flex';
import RBSheet from 'react-native-raw-bottom-sheet';
import Text from '@components/General/Text';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';

const { height } = Dimensions.get('window');

type FormField = {
  label: string;
  value: string;
  onChange: (value: object) => void;
};

export default function AddressInput(props: FormField) {
  const refRBSheet = useRef();
  let [label, setLabel] = useState('Add Address');

  return (
    <>
      <View className="w-full h-auto p-3 rounded-xl text-xs mb-4 border border-denim-20">
        <TouchableOpacity onPress={() => refRBSheet.current.open()}>
          <Text type="xs-semibold">{label}</Text>
        </TouchableOpacity>

        <RBSheet
          ref={refRBSheet}
          animationType={'fade'}
          height={height * 0.9}
          customStyles={{
            container: {
              borderTopLeftRadius: 28,
              borderTopRightRadius: 28,
              width:
                getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
              marginHorizontal:
                getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
            },
          }}
        >
          <Flex className="flex-1 p-5">
            <GooglePlacesAutocomplete
              placeholder="Search"
              onPress={(data, details = null) => {
                setLabel(data.description);

                let address = {};

                let tmp = details.adr_address.split(' <span ');

                tmp.forEach((value) => {
                  value = value.replace('<span ', '');
                  value = value.replace('class=\"', '');
                  value = value.replace('</span>,', '');
                  value = value.replace('</span>', '');
                  value = value.split('\">');

                  address[value[0]] = value[1];
                });

                if (address['country-name'] == 'USA') {
                  address['country-name'] = 'United States';
                }

                const info = {
                  street: address['street-address'],
                  city: address['locality'],
                  state: address['region'],
                  country: address['country-name'],
                  zip_code: address['postal-code'],
                };

                props.onChange(info);
                refRBSheet.current.close();
              }}
              query={{
                key: 'AIzaSyAJmkyI9D_Ah00yRfQdGVYbqFrFUALpnQs',
                language: 'en',
              }}
              requestUrl={{
                useOnPlatform: 'web', // or "all"
                url: 'https://proxy-f-73e0cb328f7c.herokuapp.com/https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
              }}
              fetchDetails={true}
              enablePoweredByContainer={false}
            />
          </Flex>
        </RBSheet>
      </View>

      {props.value != null && (
        <View className="mt-1 mb-3 mx-2">
          <Text type="xs-semibold">Current Address: </Text>
          <Text type="xs-normal">
            {props.value.full ?? Object.values(props.value).join(', ')}
          </Text>
        </View>
      )}
    </>
  );
}
