import { StackScreenProps } from '@react-navigation/stack';
import { AuthStackParamList } from '@src/routes';
import React, { useEffect } from 'react';
import { Image, SafeAreaView } from 'react-native';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import { useNavigation } from '@react-navigation/native';
import { navigationProps } from '@interfaces/RoutesInterface';
import AsyncStorage from '@react-native-async-storage/async-storage';

type SubmissionCompleteProps = StackScreenProps<
  AuthStackParamList,
  'OfferSubmissionComplete'
>;

export default function SubmissionComplete({ route }: SubmissionCompleteProps) {
  const navigation = useNavigation<navigationProps>();

  useEffect(() => {
    removeOnboardingData();
  }, []);

  const removeOnboardingData = async () => {
    await AsyncStorage.removeItem('initialOnboarding');
  };

  const redirectToProduction = async () => {
    await AsyncStorage.setItem(
      'redirectTo',
      JSON.stringify({
        page: 'Production',
        params: {
          productionId: route.params.productionId,
        },
      })
    );

    navigation.navigate('Home');
  };

  return (
    <Flex className="flex-1 bg-navy-light-100">
      <SafeAreaView className="flex-1">
        <Flex verticalAlign="between" className="flex-1 mx-7 mt-24">
          <Flex className="h-80" verticalAlign="between">
            <Image
              source={require('@assets/images/logo.png')}
              className="self-center h-10 w-full"
              resizeMode="contain"
            />

            <Flex align="center" className="gap-y-4">
              <Text type="xl-semibold" color="white/100">
                Submission Complete!
              </Text>
              <Text type="sm-medium" color="white/100" className="text-center">
                You’re now scheduled for this production.
              </Text>
              <Text
                type="sm-medium"
                color="white/100"
                className="text-center opacity-70"
              >
                All of your documents have been submitted, and copies have been
                saved to your profile for use on all future FilmUp productions.
              </Text>
            </Flex>
          </Flex>

          <Button
            useFullColor={true}
            value="Go to Production"
            color="white"
            fontColor="navy-light-100"
            className="mb-5"
            onPress={() => redirectToProduction()}
          />
        </Flex>
      </SafeAreaView>
    </Flex>
  );
}
