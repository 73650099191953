import { ActivityIndicator, StyleProp, TouchableOpacity } from 'react-native';
import React from 'react';
import Text from '@components/General/Text';
import { opacity } from 'react-native-reanimated/lib/types';

type ButtonProps = {
  value: string;
  color?: string;
  useFullColor?: boolean;
  radius?: string;
  className?: string;
  onPress?: () => void;
  type?: 'md' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  fontColor?: string;
  weight?: string;
};

export default function Button({
  value,
  color = 'navy-light-100',
  useFullColor = false,
  type = 'lg',
  weight,
  loading = false,
  disabled = false,
  ...props
}: ButtonProps) {
  const height = type === 'lg' ? 'h-10' : 'h-8';
  const textType = weight ? weight : type === 'lg' ? 'sm-bold' : 'sm-semibold';
  const background = useFullColor ? `bg-${color}` : `bg-${color}/20`;
  const fontColor = props.fontColor
    ? props.fontColor
    : useFullColor
      ? 'white/100'
      : color;

  return (
    <TouchableOpacity
      className={`rounded-${type} px-5 ${height} justify-center items-center ${background} ${
        loading || disabled ? 'opacity-50' : ''
      }`}
      {...props}
      disabled={loading || disabled}
    >
      {loading && <ActivityIndicator />}
      {!loading && (
        <Text type={textType} color={fontColor}>
          {value}
        </Text>
      )}
    </TouchableOpacity>
  );
}
