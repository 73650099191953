import React, { useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import productionApi from '@api/productionApi';
import {
  productionsType,
  productionType,
} from '@interfaces/ProductionInterface';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Tag from '@components/General/Tag';
import Text from '@components/General/Text';
import { formatDateDefault } from '@src/helpers';
import { RefreshControl } from 'react-native-web-refresh-control';
import NoData from '@components/General/NoData';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { navigationProps } from '@interfaces/RoutesInterface';

export default function ProductionsHistory() {
  const [productions, setProductions] = useState<productionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<navigationProps>();

  useFocusEffect(
    React.useCallback(() => {
      getProductions();
    }, [])
  );

  const getProductions = async () => {
    setLoading(true);
    let response: productionsType = await productionApi.getProductions();

    setProductions(response.data);
    setLoading(false);
  };

  return (
    <Flex className="flex-1 px-5">
      <ScrollView
        className="flex-1"
        style={{ paddingBottom: insets.bottom == 0 ? 20 : insets.bottom }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={getProductions} />
        }
      >
        {productions.length == 0 && !loading && (
          <NoData text="You don't have any productions yet." />
        )}

        {productions.length > 0 &&
          !loading &&
          productions.map((production, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  navigation.navigate('Production', {
                    productionId: production.id,
                  });
                }}
              >
                <Flex className="border-b border-gray-200 py-4">
                  <FlexRow
                    verticalAlign="center"
                    align="between"
                    className="mb-2"
                  >
                    <Text type="sm-medium">{production.attributes.name}</Text>
                    <Tag type={production.attributes.status} />
                  </FlexRow>
                  <FlexRow
                    verticalAlign="center"
                    align="between"
                    className="mb-1"
                  >
                    <Text type="xs-medium" color="denim-50">
                      {production.related.company.data.attributes.name}
                    </Text>
                    <Text type="xs-medium" color="denim-50">
                      {formatDateDefault(
                        production.attributes.first_shooting_day
                      )}{' '}
                      -{' '}
                      {formatDateDefault(
                        production.attributes.last_shooting_day
                      )}
                    </Text>
                  </FlexRow>
                </Flex>
              </TouchableOpacity>
            );
          })}
      </ScrollView>
    </Flex>
  );
}
