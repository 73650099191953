import React, { Fragment } from 'react';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { TouchableOpacity } from 'react-native-gesture-handler';

type LocationsProps = {
  cities: { location: string; date: string }[];
};

export const Locations = ({ cities }: LocationsProps) => {
  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5">
        Locations & Dates
      </Text>

      <FlexRow align="between" className="bg-slate-800/5 py-4">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Location City
        </Text>
        <Text type="xs-medium" color="denim-50" className="px-6">
          Working Dates
        </Text>
      </FlexRow>

      <Flex className="px-6">
        {cities.map((city) => (
          <FlexRow
            key={city.location}
            verticalAlign="center"
            align="between"
            className="h-11 border-b-0.5 border-denim-20"
          >
            <Text type="sm-medium" color="denim-50">
              {city.location}
            </Text>
            <Text type="sm-medium" color="slate-800">
              {city.date}
            </Text>
          </FlexRow>
        ))}
      </Flex>
    </Fragment>
  );
};
