import moment from 'moment';

export const getElapsedHours = (
  checkin: string | null,
  checkout: string | null
) => {
  const timeIn = moment(checkin);
  const timeOut = moment(checkout);

  let totalMinutes = timeOut.diff(timeIn, 'minutes');

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;

  if (isNaN(hours) || isNaN(minutes)) {
    return '--.--';
  }

  return formattedTime;
};
