import React, { Fragment } from 'react';

import Flex from '@components/Views/Flex';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { TouchableOpacity } from 'react-native-gesture-handler';

type ProductionDetailsProps = {
  title: string;
  type: string;
  onOpenSynopsis: () => void;
};

export const ProductionDetails = ({
  title,
  type,
  onOpenSynopsis,
}: ProductionDetailsProps) => {
  const details = [
    {
      title: 'Title',
      value: title,
    },
    {
      title: 'Type',
      value: type,
    },
    {
      title: 'Synopsis',
      value: (
        <TouchableOpacity onPress={onOpenSynopsis}>
          <Text type="xs-medium" color="navy-light-100">
            View Synopsis
          </Text>
        </TouchableOpacity>
      ),
    },
  ];

  return (
    <Fragment>
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5">
        Production Details
      </Text>

      <Flex className="bg-slate-800/5 py-4">
        <Text type="xs-medium" color="denim-50" className="px-6">
          Information about this production.
        </Text>
      </Flex>

      <Flex className="px-6">
        {details.map((detail) => (
          <FlexRow
            key={detail.title}
            verticalAlign="center"
            align="between"
            className="h-11 border-b-0.5 border-denim-20"
          >
            <Text type="sm-medium" color="denim-50">
              {detail.title}
            </Text>
            <Text type="sm-medium" color="slate-800">
              {detail.value}
            </Text>
          </FlexRow>
        ))}
      </Flex>
    </Fragment>
  );
};
