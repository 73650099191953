import { ScrollView, TouchableOpacity } from 'react-native';
import Flex from '@components/Views/Flex';
import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import { formatDateDefaultYear, transformTailwindToHex } from '@src/helpers';
import Tag from '@components/General/Tag';
import Button from '@components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import productionApi from '@api/productionApi';
import SignDocsModal from '@components/Modals/SignDocsModal';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import FloatingBottom from '@components/Views/FloatingBottom';
import CheckRounded from '@assets/icons/check-rounded.svg';

export default function SignDocs({ offerId, nextStep }) {
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [documentsToSign, setDocumentsToSign] = useState<any>([]);
  const [documentsToUpload, setDocumentsToUpload] = useState<any>([]);
  const [signDocsModalVisible, setSignDocsModalVisible] =
    useState<boolean>(false);
  const [haveDocsToSign, setHaveDocsToSign] = useState<boolean>(true);
  const [resignDocs, setResignDocs] = useState<boolean>(false);
  const [docsToResign, setDocsToResign] = useState<any>([]);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    setLoading(true);
    let response = await productionApi.getOfferDocuments(offerId);

    let docsSign = response.data.filter(
      (doc: any) => doc.attributes.type == 'signature'
    );
    setDocumentsToSign(docsSign);

    let docsUpload = response.data.filter(
      (doc: any) => doc.attributes.type == 'upload'
    );
    setDocumentsToUpload(docsUpload);

    let docsPendingSign = docsSign.filter(
      (doc: any) => !doc.attributes.is_completed
    );
    setHaveDocsToSign(docsPendingSign.length > 0);

    setLoading(false);
  };

  const handleCheck = (value: string) => {
    let updatedList = [...docsToResign];

    if (docsToResign.includes(value)) {
      updatedList.splice(docsToResign.indexOf(value), 1);
      setDocsToResign(updatedList);
      return;
    }

    updatedList = [...docsToResign, value];
    setDocsToResign(updatedList);
  };

  const getSignDocumentURL = async () => {
    setLoading(true);

    let documents = {};

    if (docsToResign.length > 0) {
      documents = { selection: docsToResign };
    }

    let response = await productionApi.getSignDocumentURL(offerId, documents);

    setUrl(response.data.url);
    setSignDocsModalVisible(true);
  };

  return (
    <>
      <Flex className="flex-1 mt-5" verticalAlign="between">
        <ScrollView className="flex-1">
          <Flex className="flex-1 px-5">
            {documentsToSign.map((document: any) => (
              <FlexRow
                className="py-3 mb-2 border-b-0.5 border-denim-20"
                verticalAlign="center"
                align="between"
              >
                {resignDocs && (
                  <TouchableOpacity
                    className="border border-denim-100 h-6 w-6 bg-denim-5 rounded-full"
                    onPress={() => handleCheck(document.id)}
                  >
                    {docsToResign.includes(document.id) && (
                      <CheckRounded
                        width={22}
                        height={22}
                        fill={transformTailwindToHex('denim-100')}
                      />
                    )}
                  </TouchableOpacity>
                )}
                <Flex className="flex-grow ml-2">
                  <Text type="xs-bold">{document.attributes.title}</Text>
                  <Text type="xs-medium" color="denim-50">
                    Last Updated:{' '}
                    {formatDateDefaultYear(
                      document.attributes.last_updated_at
                    ) ?? 'N/A'}
                  </Text>
                </Flex>
                <Tag
                  type={
                    document.attributes.is_completed ? 'signed' : 'not signed'
                  }
                />
              </FlexRow>
            ))}
          </Flex>
        </ScrollView>
        <FloatingBottom>
          {haveDocsToSign && (
            <Button
              useFullColor={true}
              value="Sign Documents"
              color="coral-100"
              className="mt-2"
              onPress={() => getSignDocumentURL()}
              loading={loading}
            />
          )}
          {!haveDocsToSign && !resignDocs && (
            <SecondaryButton
              value="Select Documents to Re-Sign"
              className="mt-2"
              onPress={() => setResignDocs(true)}
              loading={loading}
            />
          )}
          {!haveDocsToSign && resignDocs && (
            <SecondaryButton
              value="Cancel Re-Sign Documents"
              className="mt-2"
              onPress={() => {
                setResignDocs(false);
                setDocsToResign([]);
              }}
              loading={loading}
            />
          )}
          {!haveDocsToSign && resignDocs && (
            <SecondaryButton
              value="Re-Sign Documents"
              className="mt-2"
              onPress={() => getSignDocumentURL()}
              loading={loading}
            />
          )}
          {!haveDocsToSign && (
            <Button
              useFullColor={true}
              value="Go To Next Step"
              color="navy-light-100"
              className="mt-2"
              onPress={() => nextStep(documentsToUpload.length)}
            />
          )}
        </FloatingBottom>
      </Flex>

      <SignDocsModal
        isVisible={signDocsModalVisible}
        url={url}
        onFinish={() => {
          setSignDocsModalVisible(false);
          nextStep(documentsToUpload.length);
        }}
        onCloseModal={() => setSignDocsModalVisible(false)}
      />
    </>
  );
}
