import React from 'react';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import { Image, SafeAreaView, TouchableOpacity } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { NonAuthStackParamList } from '@src/routes';
import Button from '@components/Buttons/Button';
import Header from '@components/Views/Header';

import FlexRow from '@components/Views/FlexRow';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

type HowItWorksProps = StackScreenProps<NonAuthStackParamList, 'HowItWorks'>;

export default function HowItWorks({ navigation }: HowItWorksProps) {
  const insets = useSafeAreaInsets();

  return (
    <Flex className="flex-1 bg-navy-light-100">
      <Header title="Welcome" showBack={false} />
      <SafeAreaView className="flex-1">
        <Flex verticalAlign="between" className="flex-grow mx-10 mt-12">
          <Flex>
            <Image
              source={require('@assets/images/logo.png')}
              className="self-center h-10 w-full"
              resizeMode="contain"
            />

            <Flex className="mb-6 pt-10">
              <Text
                type="lg-semibold"
                color="white/100"
                className="mb-2 text-left"
              >
                This production is using FilmUp to staff, manage, & pay crew.
              </Text>
              <Text
                type="xs-semibold"
                color="denim-20"
                className="mb-12 text-left"
              >
                You will be required to use the FilmUp App to submit your
                documents & timecards.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className="gap-y-5 bg-white h-2/3 rounded-t-2xl justify-between"
          verticalAlign="between"
          align="between"
        >
          <Flex className="mt-5">
            <Text type="sm-semibold" color="navy-100" className="mb-2 mx-12">
              How it Works
            </Text>
            <hr className="w-full mt-2.5 mb-4" />
            {[
              {
                blueText: 'Sign & Submit',
                text: ' Onboarding Documents',
              },
              {
                blueText: 'Complete & Approve',
                text: ' All Daily Timecards',
              },
              {
                blueText: 'Access e Review',
                text: ' Production Schedules',
              },
              {
                blueText: 'Track & Manage',
                text: ' Paystubs & Tax docs',
              },
            ].map((row) => (
              <FlexRow
                verticalAlign="center"
                align="start"
                className="mb-3 mx-12"
              >
                <FlexRow className="flex-grow" verticalAlign="center">
                  <Text type="base-semibold" color="navy-light-100">
                    {row.blueText}
                  </Text>
                  <Text type="sm-semibold" color="denim-100">
                    {row.text}
                  </Text>
                </FlexRow>
              </FlexRow>
            ))}
          </Flex>

          <Flex>
            <Text type="xs-semibold" color="denim-30" className="text-center">
              Already have an account?{' '}
              <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                <Text color="navy-light-100" className="underline">
                  Sign In
                </Text>
              </TouchableOpacity>
            </Text>
            <Button
              value="Let’s Do This"
              onPress={() => navigation.navigate('GettingStarted')}
              color="navy-light-100 mx-10 mt-4 mb-10"
              useFullColor={true}
            />
          </Flex>
        </Flex>
      </SafeAreaView>
    </Flex>
  );
}
