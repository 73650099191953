import React, { forwardRef } from 'react';
import RBSheet from 'react-native-raw-bottom-sheet';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import SecondaryButton from '@components/Buttons/SecondaryButton';

interface DetailsModalProps {
  title: string;
  content: string;
}

export const DetailsModal = forwardRef<RBSheet, DetailsModalProps>(({ title, content }, ref) => (
  <RBSheet
    ref={ref}
    animationType="fade"
    height={600}
    customStyles={{
      container: {
        borderTopLeftRadius: 28,
        borderTopRightRadius: 28,
        width: getGlobal().deviceType === DeviceType.DESKTOP ? '500px' : '100%',
        marginHorizontal: getGlobal().deviceType === DeviceType.DESKTOP ? 'auto' : 0,
      },
    }}
  >
    <Flex className="p-8 h-full gap-y-2" verticalAlign="between">
      <Flex align="start">
        <Text type="sm-semibold" color="navy-light-100" className="mb-2">
          {title}
        </Text>
        <Text type="xs-medium" color="denim-100/60">
          {content}
        </Text>
      </Flex>
      <SecondaryButton
        value="Close"
        onPress={() => (ref as React.RefObject<RBSheet>).current?.close()}
      />
    </Flex>
  </RBSheet>
)); 