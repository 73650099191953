import { TextInput as RNTextInput } from 'react-native';
import React from 'react';
import Text from '@components/General/Text';
import RadioGroup from 'react-native-radio-buttons-group';
import Flex from '@components/Views/Flex';
import RNPickerSelect from 'react-native-picker-select';

type FormField = {
  label: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
};

export default function SelectInput(props: FormField) {
  return (
    <Flex className="w-full h-12 bg-denim-100/5 pt-4 pb-2 px-3 rounded-xl text-sm mb-4">
      <RNPickerSelect
        placeholder={{ label: props.label }}
        onValueChange={(text) => props.onChange(text)}
        items={
          props.options?.map((option, index) => {
            return { label: option, value: option };
          }) ?? []
        }
        value={props.value}
      />
    </Flex>
  );
}
