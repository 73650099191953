import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import React from 'react';
import { capitalizeFirstLetter } from '@src/helpers';

type rateType = 'hour' | 'hour-day' | 'day' | 'week' | 'production';
type kitType = 'day' | 'week';
export type rateInfoType = {
  amount: number;
  guaranteed_hours: number | null;
  hours: number;
  kit_fee: { type: kitType; amount: number };
  type: rateType;
};

export type NoDataProps = {
  rate: rateInfoType;
  color: string;
};

export const translatedRateAmount = (rate: rateInfoType) => {
  const type = rate.type == 'production' ? 'project' : rate.type;
  let guaranteedString = '';

  if (rate.guaranteed_hours != null && rate.guaranteed_hours > 0) {
    guaranteedString = `(${rate.guaranteed_hours}h gtd.)`;
  }

  switch (type) {
    case 'hour':
      return `${translatedAmount(rate.amount)} / hr ${guaranteedString}`;

    case 'hour-day':
      return `${translatedAmount(rate.amount)} / ${rate.hours}h Day ${guaranteedString}`;

    case 'day':
    case 'week':
    case 'project':
      return `${translatedAmount(rate.amount)} / ${capitalizeFirstLetter(type)}`;
  }
};

export function translatedKitFee(rate) {
  const kit_fee = rate.kit_fee;

  if (kit_fee == null) {
    return 'N/A';
  }

  return `${translatedAmount(kit_fee.amount)} / ${capitalizeFirstLetter(kit_fee.type)}`;
}

export function translatedGuaranteedHours(rate: rateInfoType) {
  if (rate.guaranteed_hours == null) {
    return 'N/A';
  }

  return `${rate.guaranteed_hours} hours`;
}

export function translatedAmount(amount) {
  return `$${(Math.round(amount * 100) / 100).toFixed(2)}`;
}

export default function Rates({ rate, color }: NoDataProps) {
  return (
    <Text type="sm-bold" color={`${color}/60`}>
      ${translatedRateAmount(rate)}
    </Text>
  );
}
