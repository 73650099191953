import React, { useRef, useState } from 'react';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import Flex from '@components/Views/Flex';

import Text from '@components/General/Text';
import FlexRow from '@components/Views/FlexRow';
import RBSheet from 'react-native-raw-bottom-sheet';
import Button from '@components/Buttons/Button';

import CheckCircle from '@assets/icons/check-circle.svg';
import AlertCircle from '@assets/icons/alert-circle.svg';
import CircleDashed from '@assets/icons/circle-dashed.svg';
import LockCircle from '@assets/icons/lock-circle.svg';

import productionApi from '@api/productionApi';
import {
  formatTimecardtime,
  isEmpty,
  transformTailwindToHex,
  validateTime,
} from '@src/helpers';
import { ShootType } from '@interfaces/ProductionInterface';
import { showMessage } from 'react-native-flash-message';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';
import TimeInput from '@components/Forms/TimeInput';
import moment from 'moment';

const { height } = Dimensions.get('window');

interface DailyRowProps {
  label: string;
  value: string | null;
  disabled: boolean;
  locked: boolean;
  shootId: string;
  type: string;
  completedBy: null | string;
  approved: boolean;
  updateTimeCard: (shoot: ShootType) => void;
  date: string;
}

export function DailyRow({
  label,
  value,
  locked,
  shootId,
  type,
  completedBy,
  approved,
  updateTimeCard,
  date,
}: DailyRowProps) {
  const [time, setTime] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTimeType, setSelectedTimeType] = useState<string>('');
  const refRBSheet = useRef<RBSheet>(null);

  const save = async () => {
    const timecardTime = validateTime(time, selectedTimeType);

    if (timecardTime == null) {
      showMessage({
        message: 'Invalid Time',
        description: 'Please enter a valid time',
        type: 'danger',
      });

      return;
    }

    setLoading(true);

    let response: { data: ShootType } = await productionApi.postShootTimecard(
      shootId,
      {
        [type]: timecardTime,
      }
    );

    if (response?.data != null) {
      updateTimeCard(response.data);
    }

    setLoading(false);
    refRBSheet?.current?.close();
  };

  const setTimeType = (type: string) => {
    setSelectedTimeType(type);
  };

  const checkifTimeIsMoreThen12 = () => {
    if (time != null) {
      let timeArr = time.split(':');

      if (parseInt(timeArr[0]) > 12) {
        return true;
      }
    }

    return false;
  };

  const getButtonFontColor = () => {
    if (approved) {
      return 'emerald-100';
    }

    if (moment().isBefore(moment(date))) {
      return 'denim-50';
    }

    if (locked && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100';
    }

    if (completedBy != null && completedBy !== 'Self') {
      return 'grape-100';
    }

    return 'white/100';
  };

  const getButtonBackgroundColor = () => {
    if (approved) {
      return 'emerald-100/10';
    }

    if (locked && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100/10';
    }

    if (completedBy != null && completedBy !== 'Self') {
      return 'grape-100/10';
    }

    if (!locked && completedBy != null && completedBy == 'Self') {
      return 'navy-light-100';
    }

    if (moment().isBefore(moment(date))) {
      return 'denim-10';
    }

    if (!locked && !value) {
      return 'coral-100';
    }

    return 'denim-50';
  };

  const getStatus = () => {
    if (approved) {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="emerald-100">
          Approved
        </Text>
      );
    }

    if (locked && completedBy != null && completedBy == 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="navy-light-100">
          Admin Confirmed
        </Text>
      );
    }

    if (locked && completedBy != null && completedBy !== 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="grape-100">
          Admin Updated
        </Text>
      );
    }

    if (!locked && completedBy != null && completedBy !== 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="grape-100">
          Admin Updated
        </Text>
      );
    }

    if (moment().isBefore(moment(date))) {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="denim-50">
          Not Active Yet
        </Text>
      );
    }

    if (!locked && completedBy != null && completedBy == 'Self') {
      return (
        <Text type="xs-medium" className="w-full ml-3.5" color="navy-light-100">
          Complete
        </Text>
      );
    }

    return (
      <Text type="xs-medium" className="w-full ml-3.5" color="coral-100">
        Required
      </Text>
    );
  };

  const getButtonValue = () => {
    if (moment().isBefore(moment(date))) {
      return 'Locked';
    }

    if (value) {
      return moment(value).parseZone().format('h:mma').replace('m', '');
    }

    return 'Add';
  };

  const renderContent = () => {
    return (
      <View
        className={`flex flex-row border-b py-5 px-6 border-gray-300 justify-between ${
          !value && !moment().isBefore(moment(date))
            ? 'bg-coral-100/10'
            : 'bg-white'
        }`}
      >
        <FlexRow align="between" verticalAlign="center" className="w-full">
          <Flex className="w-1/2">
            <FlexRow verticalAlign="center">
              {approved && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('emerald-100')}
                />
              )}

              {!approved && locked && !completedBy === null && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('denim-50')}
                />
              )}

              {moment().isBefore(moment(date)) && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('denim-50')}
                />
              )}

              {!approved &&
                locked &&
                completedBy != null &&
                completedBy == 'Self' && (
                  <LockCircle
                    width={20}
                    height={20}
                    fill={transformTailwindToHex('navy-light-100')}
                  />
                )}

              {locked && completedBy != null && completedBy !== 'Self' && (
                <LockCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('grape-100')}
                />
              )}

              {!value && !moment().isBefore(moment(date)) && (
                <AlertCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('coral-100')}
                />
              )}

              {!locked && completedBy != null && completedBy == 'Self' && (
                <CheckCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('navy-light-100')}
                />
              )}

              {!locked && completedBy != null && completedBy !== 'Self' && (
                <CheckCircle
                  width={20}
                  height={20}
                  fill={transformTailwindToHex('grape-100')}
                />
              )}

              <Flex>
                <Text type="sm-semibold" className="w-full ml-3.5 mb-1">
                  {label}
                </Text>
                {getStatus()}
              </Flex>
            </FlexRow>
          </Flex>
          <Flex className="w-1/2">
            <Button
              useFullColor={true}
              disabled={
                locked ||
                (completedBy != null && completedBy != 'Self') ||
                moment().isBefore(moment(date))
              }
              value={getButtonValue()}
              color={getButtonBackgroundColor()}
              className="rounded-sm w-full"
              weight="sm-medium"
              fontColor={getButtonFontColor()}
              onPress={() => {
                if (!isEmpty(value)) {
                  let tmpTime = formatTimecardtime(value).split(' ');

                  setTime(tmpTime[0]);

                  if (tmpTime[1] == 'a') {
                    setSelectedTimeType('am');
                  }

                  if (tmpTime[1] == 'p') {
                    setSelectedTimeType('pm');
                  }
                }
                refRBSheet?.current?.open();
              }}
            />
          </Flex>
        </FlexRow>
      </View>
    );
  };

  return (
    <Flex>
      {renderContent()}
      {/* @ts-ignore */}
      <RBSheet
        ref={refRBSheet}
        animationType={'fade'}
        height={height < 310 ? height * 0.9 : 310}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              //@ts-ignore
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              //@ts-ignore
              (getGlobal().deviceType as number) == DeviceType.DESKTOP
                ? 'auto'
                : 0,
          },
        }}
      >
        <View>
          <Flex className="flex-1 p-8" verticalAlign="space-between">
            <Flex className="flex-grow">
              <Text type="sm-semibold" color="navy-100">
                {label}
              </Text>

              <FlexRow
                align="space-between"
                verticalAlign="center"
                className="my-5"
              >
                <TimeInput
                  label="Select Time"
                  value={time}
                  onChange={setTime}
                />

                <FlexRow verticalAlign="center" className="ml-2">
                  <TouchableOpacity
                    className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${
                      selectedTimeType == 'am' ? 'bg-navy-light-100 ' : ''
                    } ${checkifTimeIsMoreThen12() ? 'opacity-50' : ''}`}
                    onPress={() => setTimeType('am')}
                    disabled={checkifTimeIsMoreThen12()}
                  >
                    <Flex
                      className="flex-1"
                      verticalAlign="center"
                      align="center"
                    >
                      <Text
                        type="sm-medium"
                        color={
                          selectedTimeType == 'am'
                            ? 'white/100'
                            : !isEmpty(selectedTimeType)
                            ? 'denim-50'
                            : 'coral-100'
                        }
                      >
                        AM
                      </Text>
                    </Flex>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${
                      selectedTimeType == 'pm' ? 'bg-navy-light-100' : ''
                    }`}
                    onPress={() => setTimeType('pm')}
                  >
                    <Flex
                      className="flex-1"
                      verticalAlign="center"
                      align="center"
                    >
                      <Text
                        type="sm-medium"
                        color={
                          selectedTimeType == 'pm'
                            ? 'white/100'
                            : !isEmpty(selectedTimeType)
                            ? 'denim-50'
                            : 'coral-100'
                        }
                      >
                        PM
                      </Text>
                    </Flex>
                  </TouchableOpacity>
                </FlexRow>
              </FlexRow>
            </Flex>
            <Flex className="gap-y-2">
              <SecondaryButton
                value="Cancel"
                onPress={() => refRBSheet?.current?.close()}
              />
              <Button
                value="Save"
                onPress={() => save()}
                useFullColor={true}
                loading={loading}
                disabled={
                  isEmpty(selectedTimeType) || isEmpty(time) || time == ':'
                }
              />
            </Flex>
          </Flex>
        </View>
      </RBSheet>
    </Flex>
  );
}
