import React, { useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Flex from '@components/Views/Flex';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SubContentTabs from '@components/General/SubContentTabs';
import { productionType } from '@interfaces/ProductionInterface';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import { formatDateDefaultYear } from '@src/helpers';
import { navigationProps } from '@interfaces/RoutesInterface';
import { useNavigation } from '@react-navigation/native';
import { GuarantedHours } from './OverviewTab/GuarantedHours';
import { Details } from './OverviewTab/Details';
import { YourProductionContact } from '@src/components/General/YourProductionContact';
import { PaystubList } from './PaystubTab/PaystubList';
import { Documents } from './DocumentsTab/Documents';
import {translatedGuaranteedHours} from "@components/General/Rates";

type YourRoleTabProps = {
  production: productionType;
  tab: string;
};
export const NewYourRoleTab = ({ production, tab }: YourRoleTabProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(tab);

  const navigation = useNavigation<navigationProps>();
  const insets = useSafeAreaInsets();

  return (
    <Flex className="flex-1">
      <ScrollView
        className="flex-1 pt-7"
        style={{ paddingBottom: insets.bottom }}
      >
        <Flex className="flex-grow gap-y-1 pb-4 mb-4 px-6">
          <Text type="sm-bold" color="navy-100">
            {production.attributes.role}
          </Text>
          <FlexRow verticalAlign="center" align="between">
            <Text type="xs-medium" color="denim-50">
              Accepted On:{' '}
              {formatDateDefaultYear(production.attributes.accepted_at)}
            </Text>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('Offer', {
                  offerId: production.attributes.offer_id,
                })
              }
            >
              <Text type="xs-medium" color="navy-light-100">
                View Offer
              </Text>
            </TouchableOpacity>
          </FlexRow>
        </Flex>

        <SubContentTabs
          values={['Overview', 'Documents', 'Paystubs']}
          align="start"
          defaultSelected={selectedTab}
          onSelected={(value) => {
            setSelectedTab(value);

            navigation.navigate('Production', {
              tab: 'your-role',
              subtab: value,
              productionId: production.id,
            });
          }}
        />

        {selectedTab == 'overview' && (
          <>
            <Flex className="bg-[#F9CA254D] py-4 border mb-8 border-[#131E3C33]">
              <Text type="xs-medium" color="denim-50" className="px-6">
                All information about this role, including your pay rate,
                guaranteed hours, production contact, etc.
              </Text>
            </Flex>

            <Details production={production} />

            <GuarantedHours hoursAmount={translatedGuaranteedHours(production.attributes.rate)}/>

            <YourProductionContact production={production} />
          </>
        )}

        {selectedTab == 'documents' && <Documents production={production} />}

        {selectedTab === 'paystubs' && <PaystubList />}
      </ScrollView>
    </Flex>
  );
};
