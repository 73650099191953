import React, { createElement } from 'react';

type FormField = {
  label: string;
  value: string;
  defaultValue: string;
  onChange: (value: string) => void;
};

export default function DateInput(props: FormField) {
  return createElement('input', {
    type: 'date',
    value: props.value,
    onInput: (event) => props.onChange(event.target.value),
    className:
      'h-12 px-3 pt-2 pb-3 rounded-xl text-sm mb-2 border border-denim-20',
    style: {
      border: '1px solid #D0D2D8',
    },
  });
}

// import React, {createElement} from "react";
//
// type FormField = {
//     label: string,
//     value: string | null,
//     defaultValue: string | null,
//     onChange: (value: string | null) => void,
// };
//
// export default function TimeInput(props: FormField) {
//     return createElement('input', {
//         type: 'time',
//         value: props.value,
//         onInput: props.onChange,
//         min: '00:00',
//         max: '11:59',
//         className: 'border rounded-xl h-11 border-denim-20 w-full text-center text-[15px] font-medium px-3',
//         style: {
//             border: '1px solid #D0D2D8',
//         }
//     })
// }
