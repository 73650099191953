import { ActivityIndicator, ScrollView } from 'react-native';
import Flex from '@components/Views/Flex';
import Button from '@components/Buttons/Button';
import React, { useState } from 'react';
import FlexRow from '@components/Views/FlexRow';
import Text from '@components/General/Text';
import TextInput from '@components/Forms/TextInput';
import FloatingBottom from '@components/Views/FloatingBottom';

type Step2Props = {
  productionId: string;
  nextStep: () => void;
};
export default function Step2({ productionId, nextStep }: Step2Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [hoursInput, setHoursInput] = useState<string>('');

  return (
    <>
      <Flex className="flex-1 mt-5" verticalAlign="between">
        <ScrollView className="flex-1 px-5">
          <Flex className="flex-1">
            {loading && <ActivityIndicator className="mt-5" />}

            {[1, 2, 3, 4, 5, 6, 7].map((invoice, index) => {
              return (
                <FlexRow
                  key={index}
                  className="gap-x-2"
                  verticalAlign="center"
                  align="between"
                >
                  <FlexRow
                    className="w-20 mb-3"
                    verticalAlign="center"
                    align="between"
                  >
                    <Text type="xs-semibold" color="navy-100">
                      May 23
                    </Text>
                    <Text type="xs-medium" color="denim-50">
                      Sun
                    </Text>
                  </FlexRow>
                  <Flex className="flex-1">
                    <TextInput
                      label="Hours"
                      value={hoursInput}
                      onChange={() => null}
                    />
                  </Flex>
                </FlexRow>
              );
            })}
          </Flex>
        </ScrollView>
        <FloatingBottom>
          <Button
            useFullColor={true}
            value="Next"
            color="navy-light-100"
            className="mt-2"
            onPress={() => nextStep()}
          />
        </FloatingBottom>
      </Flex>
    </>
  );
}
