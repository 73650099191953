import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Flex from '@components/Views/Flex';
import Header from '@components/Views/Header';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthStackParamList } from '@src/routes';
import { productionType } from '@interfaces/ProductionInterface';
import { useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import productionApi from '@api/productionApi';
import { ActivityIndicator, SafeAreaView } from 'react-native';
import { NewContentTabs } from '@src/components/General/NewContentTabs';
import { NewYourRoleTab } from './components/NewYourRoleTab';
import { WorkSchedule } from './WorkSchedule';

type ProductionProps = StackScreenProps<AuthStackParamList, 'Production'>;

export default function Production({ navigation, route }: ProductionProps) {
  const insets = useSafeAreaInsets();
  const [production, setProduction] = useState<productionType | null>(null);

  const [selectedTab, setSelectedTab] = useState<string>(
    route.params.tab ?? 'work-schedule'
  );
  const [loading, setLoading] = useState<boolean>(true);

  useFocusEffect(
    React.useCallback(() => {
      getProduction();
    }, [])
  );

  useEffect(() => {
    navigation.setParams({ ...route.params, tab: selectedTab });
  }, [selectedTab]);

  const getProduction = async () => {
    setLoading(true);
    let response: { data: productionType } = await productionApi.getProduction(
      route.params.productionId
    );

    setProduction(response.data);
    setLoading(false);
  };

  if (loading || production == null) {
    return (
      <SafeAreaView className="flex-1 bg-white p-5">
        <ActivityIndicator />
      </SafeAreaView>
    );
  }

  return (
    <Flex
      className="flex-1 bg-white"
      style={{ paddingBottom: insets.bottom == 0 ? 20 : insets.bottom }}
    >
      <Header title={production.attributes.name} />

      <NewContentTabs
        values={['Work Schedule', 'Your Role']}
        align="start"
        defaultSelected={selectedTab}
        onSelected={(value) => {
          setSelectedTab(value);

          navigation.setParams({
            tab: route.params.tab,
            productionId: production.id,
            subtab: undefined,
          });
        }}
      />

      {selectedTab === 'your-role' && (
        <NewYourRoleTab
          production={production}
          tab={route.params.subtab || 'overview'}
        />
      )}

      {selectedTab === 'work-schedule' && (
        <WorkSchedule
          production={production}
          timecardId={route.params.timecardId}
          tab={route.params.subtab}
        />
      )}
    </Flex>
  );
}
