import { useNavigation } from '@react-navigation/native';
import Text from '@src/components/General/Text';
import { View } from 'react-native';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import FloatingBottom from '@src/components/Views/FloatingBottom';
import { translatedAmount } from '@src/helpers';
import { productionType } from '@src/interfaces/ProductionInterface';
import { Bonus, Timecard } from '../../../WorkSchedule';
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from 'moment';

type PaymentsProps = {
  timecard: Timecard;
  production: productionType;
};

export const Payments = ({ timecard, production }: PaymentsProps) => {
  const navigation = useNavigation();

  const status: {
    [key: string]: React.ReactNode;
  } = {
    pending: (
      <Text type="sm-medium" color="navy-light-100">
        Pending
      </Text>
    ),

    approved: (
      <Text type="sm-medium" color="emerald-100">
        Approved
      </Text>
    ),

    rejected: (
      <Text type="sm-medium" color="denim-50">
        Rejected
      </Text>
    ),
  };

  const getBox = (expense: Bonus) => {
    const status = expense.attributes.status;

    if (status === 'pending') {
      return (
        <View className="flex flex-row w-32 justify-between items-center bg-[#0062EC1A] px-3 py-3 rounded-xl">
          <Text type="sm-medium" color="navy-light-100">
            $
          </Text>
          <Text type="sm-medium" color="navy-light-100">
            {translatedAmount(expense.attributes.amount).substring(1)}
          </Text>
        </View>
      );
    }

    if (status === 'approved') {
      return (
        <View className="flex flex-row w-32 justify-between items-center bg-emerald-100/10 px-3 py-3 rounded-xl">
          <Text type="sm-medium" color="emerald-100">
            $
          </Text>
          <Text type="sm-medium" color="emerald-100">
            {translatedAmount(expense.attributes.amount).substring(1)}
          </Text>
        </View>
      );
    }

    if (status === 'rejected') {
      return (
        <View className="flex flex-row w-32 justify-between items-center px-3 py-3 rounded-xl">
          <Text type="sm-medium" color="denim-50">
            $
          </Text>
          <Text type="sm-medium" color="denim-50">
            {translatedAmount(expense.attributes.amount).substring(1)}
          </Text>
        </View>
      );
    }
  };

  return (
    <Flex>
      {moment().isSameOrAfter(timecard.attributes.start_date) && (
        <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            Use the Add Payment button below to submit new payment requests.
          </Text>
        </Flex>
      )}

      {!moment().isSameOrAfter(timecard.attributes.start_date) && (
        <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            This week is not active yet - timecards & payments are locked until
            the scheduled start date.
          </Text>
        </Flex>
      )}

      {timecard.related.bonuses.data.length == 0 && (
        <Flex
          className="h-11 border-b-0.5 border-denim-20"
          verticalAlign="center"
          align="center"
        >
          <Text type="xs-medium" color="denim-50">
            No Payments Added
          </Text>
        </Flex>
      )}

      {timecard.related.bonuses.data.map((expense) => {
        return (
          <FlexRow
            verticalAlign="center"
            align="between"
            className="h-14 px-6 border-b border-denim-20"
          >
            <Text type="base-medium" className="w-32">
              {expense.attributes.type}
            </Text>
            {status[expense.attributes.status]}

            {getBox(expense)}
          </FlexRow>
        );
      })}

      <FloatingBottom>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('ProductionPayment', {
              productionId: production.id,
              timecardId: timecard.id,
            })
          }
          value="Add Payment"
          className="border border-denim-20 rounded-lg py-3 items-center flex justify-center"
        >
          <FlexRow verticalAlign="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M9.12166 4.08818V2.82H8.14271V4.0703C7.73231 4.10683 7.36207 4.19858 7.03198 4.34555C6.5969 4.53723 6.25594 4.81104 6.00911 5.167C5.76228 5.52297 5.63887 5.94347 5.63887 6.42852C5.63887 6.93704 5.75391 7.35754 5.98401 7.69003C6.2141 8.01861 6.53414 8.29439 6.94413 8.51735C7.3583 8.73641 7.83731 8.94372 8.38117 9.13931C8.70331 9.25275 8.9585 9.3701 9.14676 9.49136C9.3392 9.61262 9.47726 9.75148 9.56093 9.90795C9.64879 10.0644 9.69271 10.2541 9.69271 10.4771C9.69271 10.6766 9.64669 10.8565 9.55466 11.0169C9.4668 11.1734 9.33502 11.2966 9.15931 11.3866C8.98779 11.4765 8.76815 11.5215 8.5004 11.5215C8.32888 11.5215 8.15945 11.498 7.99211 11.4511C7.82476 11.4042 7.67416 11.3259 7.54028 11.2164C7.41059 11.103 7.30601 10.9524 7.22652 10.7646C7.14703 10.5729 7.10729 10.3343 7.10729 10.0488H5.3C5.3 10.5729 5.39204 11.015 5.57611 11.3748C5.76019 11.7308 6.00702 12.0163 6.3166 12.2315C6.62618 12.4466 6.97132 12.6031 7.35202 12.7009C7.57513 12.7559 7.79894 12.7948 8.02348 12.8176V13.98H9.00243V12.8182C9.39369 12.7802 9.74929 12.6961 10.0692 12.5659C10.5211 12.3782 10.8725 12.1083 11.1235 11.7562C11.3745 11.4003 11.5 10.97 11.5 10.4654C11.5 9.95685 11.385 9.5383 11.1549 9.20972C10.9248 8.88114 10.6047 8.60341 10.1947 8.37653C9.78475 8.14965 9.30783 7.93842 8.76397 7.74284C8.42928 7.61376 8.16572 7.48858 7.97328 7.36732C7.78084 7.24215 7.64487 7.10524 7.56538 6.95659C7.49008 6.80795 7.45243 6.63388 7.45243 6.43439C7.45243 6.23098 7.48799 6.05104 7.55911 5.89458C7.63441 5.7342 7.75364 5.60707 7.9168 5.51319C8.07996 5.41931 8.28914 5.37237 8.54433 5.37237C8.71586 5.37237 8.87274 5.40366 9.01498 5.46625C9.15722 5.52492 9.27854 5.61489 9.37895 5.73615C9.48354 5.85741 9.56302 6.00801 9.61741 6.18795C9.67179 6.36789 9.69899 6.57521 9.69899 6.80991H11.5C11.5 6.3718 11.431 5.98259 11.2929 5.64227C11.1549 5.30196 10.9561 5.01445 10.6968 4.77975C10.4374 4.54114 10.1278 4.3612 9.76802 4.23994C9.56578 4.16958 9.35033 4.11899 9.12166 4.08818Z"
                fill="#131E3C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.5 8.5C16.5 12.9183 12.9183 16.5 8.5 16.5C4.08172 16.5 0.5 12.9183 0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5C12.9183 0.5 16.5 4.08172 16.5 8.5ZM14.9 8.5C14.9 12.0346 12.0346 14.9 8.5 14.9C4.96538 14.9 2.1 12.0346 2.1 8.5C2.1 4.96538 4.96538 2.1 8.5 2.1C12.0346 2.1 14.9 4.96538 14.9 8.5Z"
                fill="#131E3C"
              />
            </svg>
            <Text type="base-medium" className="ml-2">
              {' '}
              Add Payment
            </Text>
          </FlexRow>
        </TouchableOpacity>
      </FloatingBottom>
    </Flex>
  );
};
